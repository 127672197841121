import styled from 'styled-components';

const Card = styled.img`
    border-radius: 8px;
`;

interface Props {
    adUrl: string;
    adHref: string;
    customDimensions?: {
        height: number | string;
        width: number | string;
    };
}
export const AdCard = (props: Props) => {
    return (
        <a href={props.adHref} target="_blank" rel="noreferrer">
            <Card
                src={props.adUrl}
                style={{
                    height: props.customDimensions?.height || '100%',
                    width: props.customDimensions?.width || '100%',
                }}
            />
        </a>
    );
};
