import styled, { css } from 'styled-components';
import { Categories } from '../../components/Categories/Categories';

import { ArticlesYouMightLike } from '../../components/ArticlesYouMightLike/ArticlesYouMightLike';
import { useEffect, useState } from 'react';
import { getArticlesList } from '../../services/articles.service';
import { ArticlesPreview } from '../../types/api';

import background from '../../assets/images/landing_page_search_background.png';
import backgroundMobile from '../../assets/images/mobile_landing_page_background.png';
import { useSelector } from 'react-redux';
import { IState } from '../../store';

const Container = styled.div`
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            margin: 16px;
            margin-top: 86px;
        `}
`;

const BackgroundImage = styled.div`
    ${(props) => css`
        background-image: url(${background});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 97px;
        left: 0;
        width: 100%;
        height: 600px;
        z-index: -1;
        ${!props.theme.screen.desktop &&
        css`
            top: 73px;
        `}
        ${props.theme.screen.mobile &&
        css`
            top: 73px;
        `}
    `}
`;
const BackgroundImageMobile = styled.div`
    ${(props) => css`
        background-image: url(${backgroundMobile});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        position: absolute;
        left: 0;
        width: 100%;
        height: 500px;
        z-index: -1;
        ${props.theme.screen.mobile &&
        css`
            top: -70px;
        `}
    `}
`;

export const LandingPage = () => {
    const [allArticles, setAllArticles] = useState<ArticlesPreview[]>([]);
    const { screenViewPort } = useSelector((state: IState) => state.global);

    useEffect(() => {
        if (allArticles?.length > 0) {
            return;
        }
        getArticlesList().then((resp) => {
            const articlesWithContent = resp.data.filter((article) => article.contentTeaser);
            setAllArticles(articlesWithContent);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            {screenViewPort !== 'mobile' && <BackgroundImage />}
            {screenViewPort === 'mobile' && <BackgroundImageMobile />}
            <Categories />
            <ArticlesYouMightLike articles={allArticles.slice(0, 6)} />
        </Container>
    );
};
