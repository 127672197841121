import styled, { css } from 'styled-components';
import eastIcon from '@mui/icons-material/East';
import { useTranslation } from 'react-i18next';
import { ArticlesPreview } from '../../types/api';
import { useNavigate } from 'react-router-dom';

const MediaCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &:hover {
        .active {
            font-weight: 600;
            transform: translateY(1px);
            &:after {
                content: '';
                height: 2px;
                display: flex;
                width: 52%;
                margin-left: 24%;
                position: relative;
                align-items: center;
                justify-content: center;
                top: 4px;
                background-color: ${(props) => props.theme.colors.green};
            }
        }
    }
`;
const HeaderWrap = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.darkGreen};
        color: ${props.theme.colors.white};
        padding: 16px;
        border-radius: 10px 10px 0 0;
        flex: 1;
    `}
`;
const Header = styled.div`
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
`;
const InfoWrap = styled.div`
    ${(props) => css`
        flex: 1;
        padding: 16px;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 4px 15px 0px ${props.theme.colors.shadow};
        .first {
            margin-bottom: 16px;
        }
    `}
`;
const Text = styled.div`
    ${(props) => css`
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: ${props.theme.colors.secondaryBlack};
    `}
`;
const Button = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        margin-top: 18px;
        color: ${props.theme.colors.green};
        width: fit-content;
        cursor: pointer;
        bottom: 18px;
    `}
`;
const TextWrap = styled.div``;
const EastIcon = styled(eastIcon)`
    margin-left: 8px;
`;

type Props = ArticlesPreview;

export const MediaCard = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToInnerMediaPage = (mediaId: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        navigate(`/pranesimai-spaudai/${mediaId}`);
    };

    return (
        <MediaCardContainer onClick={(e) => navigateToInnerMediaPage(props.id, e)}>
            <HeaderWrap>
                <Header>{props.name}</Header>
            </HeaderWrap>
            <InfoWrap>
                <Text className="first">{props.postDate}</Text>
                <Text>{props.contentTeaser}</Text>
                <Button>
                    <TextWrap className="active">{t('read')}</TextWrap>
                    <EastIcon fontSize="small" />
                </Button>
            </InfoWrap>
        </MediaCardContainer>
    );
};
