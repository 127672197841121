import styled, { css } from 'styled-components';
import img from '../../assets/images/EuProjects/eu_project.jpeg';
import { useTranslation } from 'react-i18next';

const EuProjectsContainer = styled.div`
    display: flex;
    justify-content: center;
`;
const Wrap = styled.div`
    ${(props) => css`
        margin: 0 16px;
        ${props.theme.screen.desktop &&
        css`
            max-width: 1254px;
        `}
    `}
`;
const Img = styled.img`
    ${(props) => css`
        float: right;
        width: 50%;
        margin: 0 0 30px 30px;
        border-radius: 8px;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
            margin: 0 0 24px 0;
        `}
    `}
`;
const Text = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.005em;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 18px;
        `}
    `}
`;
const Bold = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        margin: 24px 0;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            font-weight: 500;
            line-height: 18px;
            margin: 20px 0;
        `}
    `}
`;
const Info = styled.div`
    ${(props) => css`
        margin: 32px 0 48px 0;
        ${!props.theme.screen.desktop &&
        css`
            margin: 24px 0;
        `}
    `}
`;
const Header = styled.div`
    ${(props) => css`
        margin-top: 16px;
        line-height: 38px;
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
        `}
    `}
`;

export const EuProjectsPage = () => {
    const { t } = useTranslation();

    return (
        <EuProjectsContainer>
            <Wrap>
                <Header>{t('EU_projects')}</Header>
                <Info>
                    <Img src={img} />
                    <Text>
                        <Bold>{t('EU_page_bold_text')}</Bold>
                        {t('EU_page_text')}
                    </Text>
                </Info>
            </Wrap>
        </EuProjectsContainer>
    );
};
