import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CategoryCard } from './CategoryCard';
import { Link } from 'react-router-dom';

import { IState } from '../../store';
import { ROUTE_NAMES } from '../../constants/routes';

import styled, { css } from 'styled-components';
import eastIcon from '@mui/icons-material/East';
import { AdCard } from '../AdCard/AdCard';
import { BannerItem, ICategory } from '../../types/api';

const CategoriesWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 16px;
    margin-bottom: 48px;
    ${(props) =>
        !props.theme.screen.dektop &&
        css`
            margin: 0 16px 30px;
        `}
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0 24px;
    width: 100%;
    max-width: 1254px;
    color: ${(props) => props.theme.colors.darkGreen};
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            margin: 0;
        `}
`;

const Header = styled.h2`
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            font-size: 24px;
            flex: none;
        `}
`;

const Button = styled(Link)`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding: 15px 24px;
        text-decoration: none;
        border: 1px solid ${props.theme.colors.darkGreen};
        color: ${props.theme.colors.darkGreen};
        &:hover {
            border-color: ${props.theme.colors.green};
            color: ${props.theme.colors.green};
            transition: box-shadow 0.2s ease-in-out;
            cursor: pointer;
        }
    `}
`;

const Arrow = styled(eastIcon)`
    margin-left: 4px;
    font-size: 12px !important;
`;

const ContainerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            width: 100%;
            padding-top: 16px;
        `}
`;

const AdWrapper = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: ${props.theme.colors.greyBg};
        ${!props.theme.screen.desktop &&
        css`
            width: auto;
            padding: 16px 39px;
        `}
    `}
`;

const CardsWrap = styled.div`
    ${(props) => css`
        display: grid;
        max-width: 1254px;
        width: 100%;
        grid-template-columns: repeat(5, 1fr);
        gap: 30px;
        ${props.theme.screen.desktop &&
        css`
            @media screen and (max-width: 1300px) {
                grid-template-columns: repeat(4, 1fr);
            }
        `}
        ${props.theme.screen.mobile &&
        css`
            gap: 30px;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        `}
        ${props.theme.screen.tablet &&
        css`
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
        `}
    `}
`;

const RowWrapper = styled.div`
    ${(props) => css`
        display: flex;
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
            justify-content: center;
        `}
        ${props.theme.screen.tablet &&
        css`
            display: grid;
            grid-template-columns: 1fr;
            width: 100%;
        `}
    `}
`;

export const Categories = () => {
    const { t } = useTranslation();
    const { screenViewPort, bannersList } = useSelector((state: IState) => state.global);
    const { categoriesList } = useSelector((state: IState) => state.company);

    const categoriesAndBanners = (): (ICategory | BannerItem)[] => {
        const categoriesAndBannersList = [];
        if (categoriesList.length > 0) {
            for (let i = 0; i < categoriesList.length; i++) {
                switch (i) {
                    case 4:
                        if (bannersList.WBQ.length > 0) {
                            categoriesAndBannersList.push(bannersList.WBQ[0]);
                        }
                        break;
                    case 6:
                        if (bannersList.WBM.length > 0) {
                            categoriesAndBannersList.push(bannersList.WBM[0]);
                        }
                        break;
                    case 9:
                        if (bannersList.WBM.length > 1) {
                            categoriesAndBannersList.push(bannersList.WBM[1]);
                        }
                        break;
                    case 15:
                        if (bannersList.WBM.length > 2) {
                            categoriesAndBannersList.push(bannersList.WBM[2]);
                        }
                        break;
                }
                categoriesAndBannersList.push(categoriesList[i]);
            }
        }
        return categoriesAndBannersList;
    };

    const adDimensions = () => {
        if (screenViewPort === 'mobile' || screenViewPort === 'tablet') {
            return { height: 250, width: 250 };
        }
        return { height: 200, width: 200 };
    };

    return (
        <CategoriesWrapper>
            <HeaderWrapper>
                <Header>{t('all_categories')}</Header>
                <Button to={ROUTE_NAMES.ALL_CATEGORIES}>
                    {screenViewPort !== 'mobile' && t('all_companies')}
                    {screenViewPort === 'mobile' && <Arrow />}
                </Button>
            </HeaderWrapper>
            <ContainerWrapper>
                <CardsWrap>
                    {categoriesAndBanners().map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <RowWrapper>
                                    {'image' in item ? (
                                        <AdWrapper>
                                            <AdCard
                                                adUrl={item.image}
                                                adHref={item.url}
                                                customDimensions={adDimensions()}
                                            />
                                        </AdWrapper>
                                    ) : (
                                        <CategoryCard category={item} />
                                    )}
                                </RowWrapper>
                            </React.Fragment>
                        );
                    })}
                </CardsWrap>
            </ContainerWrapper>
        </CategoriesWrapper>
    );
};
