import styled, { css } from 'styled-components';

import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const CompanyStatusWrap = styled.div`
    ${(props) => css`
        margin-top: 16px;
        background-color: ${props.theme.colors.error};
        padding: 10px;
        border-radius: 8px;
        width: calc(100% - 16px);
        &.not_registered {
            background-color: ${props.theme.colors.green};
        }
        ${props.theme.screen.mobile &&
        css`
            order: 2;
        `}
    `}
`;
const Info = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: ${props.theme.colors.white};
    `}
`;
const Text = styled.div`
    margin-left: 8px;
`;

type LegalStatus = {
    status: string | null;
};

export const CompanyStatus = (props: LegalStatus) => {
    const { t } = useTranslation();
    return (
        <CompanyStatusWrap
            className={classNames({
                not_registered: props.status === 'not_registered',
            })}
        >
            <Info>
                <InfoIcon fontSize="small" />
                <Text>{t('legal_status')}:</Text>
                <Text>{t(`legal_statuses.${props.status}`)}</Text>
            </Info>
        </CompanyStatusWrap>
    );
};
