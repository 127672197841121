import { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { getArticle, getArticlesList } from '../../services/articles.service';
import { IState } from '../../store';
import { Article, ArticlesPreview, CompanyListItem, CompanySearchFilters, ICategory, Topic } from '../../types/api';
import { ArticlesYouMightLike } from '../ArticlesYouMightLike/ArticlesYouMightLike';
import { setBreadcrumb } from '../../store/global.slice';
import noImg from '../../assets/images/no_image.jpg';
import eastIcon from '@mui/icons-material/East';
import { getSearchCompanies } from '../../services/company.service';
import { BusinessListCard } from '../BusinessListCard/BusinessListCard';
import { AdCard } from '../AdCard/AdCard';
import { useTranslation } from 'react-i18next';

const ArticlesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;
const ArticleContainerWrap = styled.div`
    width: 1254px;
    margin: 0 16px;
`;
const ArticleInnerContainer = styled.div`
    display: flex;
`;

const ArticleWrap = styled.div`
    width: 100%;
    height: fit-content;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            display: flex;
            flex-wrap: wrap;
        `}
`;
const HeaderContainer = styled.div`
    align-items: center;
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 32px;
        font-weight: 500;
        line-height: 38px;
        margin-top: 8px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
        `}
    `}
`;
const Category = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.outlineGrey};
        color: ${props.theme.colors.darkGrey};
        margin: 0 16px 8px 0;
        padding: 6px 12px;
        border-radius: 8px;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0.05em;
        max-height: 26px;
        width: fit-content;
        cursor: pointer;
    `}
`;
const Img = styled.img`
    ${(props) => css`
        height: 100%;
        width: 100%;
        border-radius: 8px;
        max-height: 622px;
        object-fit: cover;
        margin-top: 24px;
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 24px;
        `}
    `}
`;
const Date = styled.div`
    ${(props) => css`
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.005em;
        margin: 24px 0;
        width: 100%;
        ${!props.theme.screen.desktop &&
        css`
            margin: 24px 0 0;
        `}
    `}
`;
const HtmlContent = styled.div`
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 8px;
        `}
    `}
`;

const CategoriesWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
`;

const Hero = styled.div`
    ${(props) => css`
        width: 100%;
        display: flex;
        gap: 30px;
        min-height: calc(100vh - 523px);
        margin-bottom: 32px;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;

const BusinessCardWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 398px);
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const BusinessWrapper = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        background-color: ${props.theme.colors.greyBg};
    `}
`;
const BusinessContainer = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        width: 1254px;
        margin: 0 16px;
    `}
`;
const BusinessHeader = styled.div`
    ${(props) => css`
        font-size: 22px;
        font-weight: 600;
        line-height: 26px;
        margin: 43px 0;
    `}
`;

const AdsWrapper = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: 1fr;
        width: 398px;
        height: fit-content;
        padding: 16px;
        gap: 30px;
        border-radius: 8px;
        border: 1px solid ${props.theme.colors.outlineGrey};
        background-color: ${props.theme.colors.outlineGrey};
        margin-bottom: 30px;
        ${!props.theme.screen.desktop &&
        css`
            display: flex;
            justify-content: center;
        `}
    `}
`;

const AdCardStyled = styled(AdCard)`
    flex: 1;
`;
const HeaderWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Button = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding: 15px 23px;
        border: 1px solid ${props.theme.colors.darkGreen};
        border-radius: 8px;
        text-decoration: none;
        color: ${props.theme.colors.darkGreen};
        &:hover {
            color: ${props.theme.colors.green};
            border-color: ${props.theme.colors.green};
        }
    `}
`;
const EastIcon = styled(eastIcon)`
    margin-left: 8px;
`;

export const ArticleInner = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [article, setArticle] = useState<Article>();
    const [similarBusinesses, setSimilarBusinesses] = useState<CompanyListItem[]>([]);
    const [filteredSameTopicArticles, setFilteredSameCategoryArticles] = useState<ArticlesPreview[]>();
    const { screenViewPort, bannersList } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();
    const urlParams = useParams();
    const { categoriesList } = useSelector((state: IState) => state.company);
    const navigate = useNavigate();

    useEffect(() => {
        if (urlParams.cardId) {
            getArticle(parseInt(urlParams.cardId)).then((resp) => {
                setArticle(resp);
                dispatch(
                    setBreadcrumb({
                        key: 'articleName',
                        value: resp.name,
                    })
                );
                const articleTopicsIds = resp.topics.map((topic) => topic.id);
                if (articleTopicsIds.length > 0) {
                    getArticlesList(1, `&topics=${articleTopicsIds?.join(',')}`).then((res) => {
                        const articlesWithContent = res.data.filter(
                            (article: ArticlesPreview) => article.contentTeaser && `${article.id}` !== urlParams.cardId
                        );
                        setFilteredSameCategoryArticles(articlesWithContent);
                    });
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams?.cardId]);

    useEffect(() => {
        const filters: CompanySearchFilters = {};
        if (article?.topics) {
            filters.topic = article.topics[0]?.id.toString();
            getSearchCompanies(1, filters).then((res) => {
                const response = res.data.slice(0, 3);
                setSimilarBusinesses(response);
            });
        }
    }, [article?.topics]);

    const onImageError = (e: SyntheticEvent<HTMLDivElement>) => {
        (e.target as HTMLImageElement).src = noImg;
    };

    const navigateToBusinessList = (topicId: number) => {
        let categoryId = '';
        if (state?.categoryId) {
            categoryId = state.categoryId;
        } else {
            const category = categoriesList.find((category: ICategory) =>
                category.topics.find((topic: Topic) => topic.id === topicId)
            );
            categoryId = category?.id.toString() || '';
        }
        navigate(`/straipsniai/${urlParams.cardId}/imoniu-sarasas/${categoryId}/${topicId}`);
    };

    const navigateToTopic = (topicId: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const category = categoriesList.find((category) => category.topics.find((topic) => topic.id === topicId));
        if (category) {
            navigate(`/imoniu-sarasas/${category.id}/${topicId}`);
        }
    };

    return (
        <>
            <ArticlesContainer>
                {article && (
                    <ArticleContainerWrap>
                        <HeaderContainer>
                            {article.topics?.length > 0 && (
                                <CategoriesWrap>
                                    {article.topics.map((topic) => (
                                        <Category onClick={(e) => navigateToTopic(topic.id, e)} key={topic.id}>
                                            {topic.name}
                                        </Category>
                                    ))}
                                </CategoriesWrap>
                            )}
                            <Header>{article.name}</Header>
                            {screenViewPort !== 'desktop' && <Date>{article.postDate}</Date>}
                        </HeaderContainer>
                        <ArticleInnerContainer>
                            <ArticleWrap>
                                {article.banner && <Img src={article.banner} onError={onImageError} />}
                                {screenViewPort === 'desktop' && <Date>{article.postDate}</Date>}
                                <HtmlContent dangerouslySetInnerHTML={{ __html: article.content }} />
                            </ArticleWrap>
                        </ArticleInnerContainer>
                    </ArticleContainerWrap>
                )}
                <ArticlesYouMightLike articles={filteredSameTopicArticles} youMayAlsoLike={true} />
            </ArticlesContainer>
            {similarBusinesses.length > 0 && (
                <BusinessWrapper>
                    <BusinessContainer>
                        <HeaderWrap>
                            <BusinessHeader>{t('related_companies')}</BusinessHeader>
                            {screenViewPort === 'desktop' && article && article.topics.length > 0 && (
                                <Button onClick={() => navigateToBusinessList(article.topics[0].id)}>
                                    {t('all_companies')}
                                    <EastIcon fontSize="small" />
                                </Button>
                            )}
                        </HeaderWrap>
                        <Hero>
                            <BusinessCardWrap>
                                {similarBusinesses.map((company, index) => (
                                    <Wrapper>
                                        <BusinessListCard key={index} company={company} />
                                    </Wrapper>
                                ))}
                            </BusinessCardWrap>
                            {screenViewPort === 'desktop' && (
                                <AdsWrapper>
                                    {bannersList.WBM.map((banner) => {
                                        return (
                                            <AdCardStyled
                                                key={banner.id}
                                                adUrl={banner.image}
                                                adHref={banner.url}
                                                customDimensions={{ height: '366px', width: '100%' }}
                                            />
                                        );
                                    })}
                                </AdsWrapper>
                            )}
                        </Hero>
                    </BusinessContainer>
                </BusinessWrapper>
            )}
        </>
    );
};
