import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ArticlesAndSidebar } from '../../components/Articles/ArticlesAndSidebar/ArticlesAndSidebar';
import { SearchAndFilter } from '../../components/Articles/SearchAndFilter/SearchAndFilter';
import { ArticlesPreview } from '../../types/api';
import { getMediaList } from '../../services/media.service';
import { useTranslation } from 'react-i18next';

const ArticlesContainer = styled.div`
    display: flex;
    justify-content: center;
`;
const Wrap = styled.div`
    width: 1254px;
    margin: 0 16px;
`;
const SearchAndFilterContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const MediaPage = () => {
    const [mediaArticles, setMediaArticles] = useState<ArticlesPreview[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        getMediaList().then((resp) => {
            const mediaWithContent = resp.data.filter((article) => article.contentTeaser);
            setMediaArticles(mediaWithContent);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mediaData = {
        header: t('press_releases'),
        inputPlaceholder: t('quick_press_release_search'),
        buttonText: t('read_more'),
    };
    return (
        <>
            <SearchAndFilterContainer>
                <Wrap>
                    <SearchAndFilter setAllMedia={setMediaArticles} media={true} />
                </Wrap>
            </SearchAndFilterContainer>
            <ArticlesContainer>
                <Wrap>
                    <ArticlesAndSidebar articleData={mediaArticles} mediaData={mediaData} isMedia={true} />
                </Wrap>
            </ArticlesContainer>
        </>
    );
};
