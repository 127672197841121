import styled, { css } from 'styled-components';
import { CompanyResponse } from '../../../../../types/api';
import { useTranslation } from 'react-i18next';
import eastIcon from '@mui/icons-material/East';
import { useState } from 'react';
import classNames from 'classnames';
import { RelatedTopics } from './RelatedTopics';

const DescriptionContainer = styled.div`
    margin-top: 24px;
`;
const DescriptionWrap = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.white};
        padding: 16px;
        border-radius: 8px 8px 0 0;
        box-shadow: 0px 4px 15px 0px ${props.theme.colors.shadow};
        ${props.theme.screen.mobile &&
        css`
            border-radius: 8px;
        `}
    `}
`;
const Name = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 8px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 17px;
        `}
    `}
`;
const Text = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 17px;
        `}
    `}
`;
const GreyContainer = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.greyBg};
        padding: 16px;
        border-radius: 8px;
    `}
`;
const Html = styled.div``;

const ButtonWrap = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        color: ${props.theme.colors.green};
        width: fit-content;
        display: flex;
        align-items: center;
        margin-top: 8px;
        cursor: pointer;
    `}
`;
const Button = styled.div`
    &:hover {
        font-weight: 600;
        &:after {
            transform: translateY(4px);
            margin-top: -1px;
            content: '';
            height: 2px;
            display: flex;
            width: 52%;
            margin-left: 24%;
            align-items: center;
            justify-content: center;
            background-color: ${(props) => props.theme.colors.green};
        }
    }
`;

const Arrow = styled(eastIcon)`
    width: 18px !important;
    margin-left: 8px;
`;

const LongDescription = styled.div`
    transition: max-height 0.7s ease;
    overflow: hidden;
    max-height: 0;
    &.slide {
        max-height: 2200px;
    }
`;

export const Description = (props: CompanyResponse) => {
    const { t } = useTranslation();
    const [descriptionOpen, setDescriptionOpen] = useState(false);

    return (
        <>
            <DescriptionContainer>
                <DescriptionWrap>
                    {props.data.topics?.length > 0 && <RelatedTopics topics={props.data.topics} />}
                    {(props.data.description.length > 0 || props.data.descriptionLong.length > 0) && (
                        <GreyContainer>
                            <Name>{t('description')}</Name>
                            {props.data.description.length > 0 && <Text>{props.data.description}</Text>}
                            <LongDescription className={classNames({ slide: descriptionOpen })}>
                                {props.data.descriptionLong.length > 0 && (
                                    <Html dangerouslySetInnerHTML={{ __html: props.data.descriptionLong }} />
                                )}
                            </LongDescription>
                            {props.data.descriptionLong && (
                                <ButtonWrap>
                                    <Button
                                        onClick={() => {
                                            setDescriptionOpen((prev) => !prev);
                                        }}
                                    >
                                        {descriptionOpen ? <>{t('hide_description')}</> : <>{t('full_description')}</>}
                                    </Button>
                                    <Arrow />
                                </ButtonWrap>
                            )}
                        </GreyContainer>
                    )}
                </DescriptionWrap>
            </DescriptionContainer>
        </>
    );
};
