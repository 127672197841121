import { FormControl, TextField, Autocomplete, createFilterOptions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const Form = styled(FormControl)`
    ${(props) => css`
        width: 100%;
        height: 58px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        background-color: ${props.theme.colors.white};
        cursor: pointer;
        &.form {
            border-radius: 0 8px 8px 0;
            ${props.theme.screen.mobile &&
            css`
                border-radius: 8px;
            `}
        }
        ${props.theme.screen.mobile &&
        css`
            border-radius: 8px;
            height: 48px;
        `}
        .root {
            &:not(.main-select) {
                border: 1px solid ${props.theme.colors.outlineGrey};
                border-radius: 0px;
                height: 52px;
            }
            display: flex;
            align-items: center;
            height: 100%;
            ${(props) => css`
                #form-input-label {
                    font-size: 16px;
                    font-weight: 400;
                    color: ${props.theme.colors.outlinesAndPlaceholders};
                    ${props.theme.screen.mobile &&
                    css`
                        font-size: 14px;
                    `}
                }
            `}
            .inputRoot {
                background-color: ${props.theme.colors.white};
                border-radius: 0;
                border: 0;
                padding-top: 20px;
                height: 48px;
                overflow: hidden;
                &:hover,
                &:focus-within {
                    border: 0;
                }
                .input {
                    font-size: 14px;
                    padding: 2px 4px 3px;
                }
            }
            &.main-select {
                #form-input-label {
                    line-height: 30px;
                    ${props.theme.screen.mobile &&
                    css`
                        padding-left: 40px;
                        line-height: 24px;
                    `}
                }
                .inputRoot {
                    background-color: ${props.theme.colors.white};
                    border: 0;
                    border-style: none none none solid;
                    height: 58px;
                    border-radius: 0 8px 8px 0;
                    &:hover,
                    &:focus-within {
                        border: 0;
                    }
                    ${props.theme.screen.mobile &&
                    css`
                        height: 48px;
                        border-radius: 8px;
                        border: solid 1px ${props.theme.colors.greyBg};
                        padding-left: 40px;
                    `}
                    .input {
                        font-size: 14px;
                    }
                }
            }
        }
        .working-hours-select {
            z-index: 200;
            .inputRoot {
                overflow: hidden;
                height: 48px;
                &:focus-within {
                    height: auto;
                }
            }
        }
    `}
`;

const StyledAutocomplete = styled(Autocomplete).attrs({
    classes: {
        root: 'root',
        inputRoot: 'inputRoot',
        input: 'input',
    },
})``;

interface Props {
    onChange: (value: any) => void;
    name: string;
    options: any[];
    id: string;
    optionLabel?: string;
    multiple?: boolean;
    class?: string;
    fieldKey?: string;
    customTranslationKey?: string;
    disabled?: boolean;
    matchFrom?: 'start' | 'any';
}

export const AutocompleteSelect = (props: Props) => {
    const { t } = useTranslation();

    const filterOptions = createFilterOptions({
        matchFrom: props.matchFrom ? props.matchFrom : 'any',
        stringify: (option: any) => optionLabel(option),
    });

    const handleChange = (value: any) => {
        props.onChange(value);
    };

    const optionLabel = (option: any) => {
        if (props.customTranslationKey) {
            return t(`${props.customTranslationKey}.${option}`);
        }
        if (props.optionLabel) {
            return option[props.optionLabel];
        }
        return option;
    };

    return (
        <>
            <Form
                className="form"
                variant="filled"
                sx={{
                    '& :after': { display: 'none' },
                    '& :before': { display: 'none' },
                    '& .MuiChip-root': {
                        width: 'auto',
                        height: '20px',
                        margin: '0 3px 8px 3px',
                        '& .MuiSvgIcon-root': {
                            fontSize: '15px',
                        },
                    },
                }}
            >
                <StyledAutocomplete
                    filterOptions={filterOptions}
                    disabled={props.disabled ? true : false}
                    autoComplete={false}
                    key={props.fieldKey}
                    className={props.class}
                    disablePortal
                    fullWidth
                    limitTags={1}
                    multiple={props.multiple ? true : false}
                    onChange={(e, value) => handleChange(value)}
                    options={props.options}
                    getOptionLabel={(option: any) => optionLabel(option)}
                    sx={{
                        opacity: props.disabled ? 0.5 : 1,
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={props.name}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                id: 'form-input-label',
                            }}
                            variant="filled"
                            size="small"
                        />
                    )}
                />
            </Form>
        </>
    );
};
