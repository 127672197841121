import styled, { css } from 'styled-components';
import { Information } from './components/Information/Information';
import { useSelector } from 'react-redux';
import { IState } from '../../store';
import { AdCard } from '../AdCard/AdCard';
import { BannerItem, CompanyResponse } from '../../types/api';
import { Charts } from './components/Charts/Charts';
import { BrandsRepresented } from './components/Information/components/BrandsRepresented';
import { Branches } from './components/Information/components/Branches';
import { Gallery } from '../Gallery/Gallery';
import { Products } from './components/Information/components/Products';
import { SearchWords } from './components/Information/components/SearchWords';

const InfoContainer = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        background-color: ${props.theme.colors.greyBg};
        ${props.theme.screen.mobile &&
        css`
            display: block;
        `}
    `}
`;
const InfoWrap = styled.div`
    display: flex;
    width: calc(100% - 32px);
    max-width: 1254px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 16px;
    gap: auto;
`;
const Hero = styled.div`
    ${(props) => css`
        width: 75%;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;
const SideBar = styled.div`
    ${(props) => css`
        width: 23%;
        ${!props.theme.screen.desktop &&
        css`
            display: flex;
            justify-content: center;
            width: 100%;
        `}
    `}
`;
const AdsWrapper = styled.div`
    ${(props) => css`
        margin-top: 30px;
        display: grid;
        grid-template-columns: 100%;
        max-width: 250px;
        gap: 16px;
        padding: 16px;
        border: 1px solid ${props.theme.colors.outlineGrey};
        ${!props.theme.screen.desktop &&
        css`
            border: none;
            padding: 0;
            max-width: 282px;
        `}
    `}
`;
const Ad = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineGrey};
        padding: 16px;
    `}
`;

export const InformationAndEmployees = (props: CompanyResponse) => {
    const { screenViewPort, bannersList } = useSelector((state: IState) => state.global);

    const isFinancialDataPresent =
        props.data.workerStatistics.totalCount?.length > 0 || props.data.financialStats.sodraDebt?.length > 0;

    const formattedBanners = () => {
        const list = [];
        if (bannersList.WBQ.length > 0) {
            list.push(bannersList.WBQ[0]);
        }
        if (bannersList.WBM.length > 0) {
            list.push(...bannersList.WBM.slice(0, 3));
        }
        if (bannersList.WBC.length > 0) {
            list.push(bannersList.WBC[0]);
        }
        if (bannersList.WBD.length > 0) {
            list.push(bannersList.WBD[0]);
        }
        return list;
    };

    return (
        <InfoContainer>
            <InfoWrap>
                <Hero>
                    <Information data={props.data} />
                </Hero>
                <SideBar>
                    <AdsWrapper>
                        {formattedBanners().map((banner: BannerItem, index: number) => {
                            if (screenViewPort !== 'desktop') {
                                return (
                                    <Ad key={index}>
                                        <AdCard adUrl={banner.image} adHref={banner.url} />
                                    </Ad>
                                );
                            }
                            return <AdCard adUrl={banner.image} key={index} adHref={banner.url} />;
                        })}
                    </AdsWrapper>
                </SideBar>
                <BrandsRepresented trademarkImages={props.data.trademarkImages} />
                {props.data.galleries.length > 0 && <Gallery galleryArray={props.data.galleries} />}
                <SearchWords searchWords={props.data.keywords} />
                <Products productsArray={props.data.products} />
                <Branches childCompanies={props.data.childCompanies} />
                {isFinancialDataPresent && (
                    <Charts
                        financialStats={props.data.financialStats}
                        employeeStatistics={props.data.workerStatistics}
                    />
                )}
            </InfoWrap>
        </InfoContainer>
    );
};
