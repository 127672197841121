import { connect } from 'react-redux';
import { IState } from '../../../store';
import { Topic } from '../../../types/api';

const PureTopicBreadcrumb = (topic: Topic) => {
    return <span>{topic?.name}</span>;
};

const mapStateToProps = (state: IState, props: any) => {
    const { categoryId, topicId } = props.match.params;
    const category = state.company.categoriesList.find((category) => category.id === parseInt(categoryId));
    const topic = category?.topics.find((topic) => topic.id === parseInt(topicId));

    return (
        topic || {
            name: '...',
        }
    );
};

export default connect(mapStateToProps)(PureTopicBreadcrumb);
