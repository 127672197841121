import React from 'react';
import styled, { css } from 'styled-components';
import { ArticleCard } from './ArticleCard';
import { ArticlesPreview } from '../../../types/api';
import { AdCard } from '../../AdCard/AdCard';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';
import { useTranslation } from 'react-i18next';

const ArticlesAndSidebarWrap = styled.div`
    ${(props) => css`
        display: flex;
        gap: 30px;
        margin-bottom: 32px;
        ${!props.theme.screen.desktop &&
        css`
            flex-wrap: wrap;
            gap: 32px;
        `};
    `};
`;
const Hero = styled.div`
    ${(props) => css`
        width: 66%;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `};
    `}
`;
const ArticleWrap = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
        ${props.theme.screen.mobile &&
        css`
            gap: 16px;
        `}
    `}
`;
const ExpandedContainer = styled.div`
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
            display: flex;
            justify-content: center;
        `}
    `}
`;
const AdsWrapper = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        background: ${(props) => props.theme.colors.greyBg};
        border-radius: 8px;
        padding: 16px;
        gap: 30px;
        ${!props.theme.screen.desktop &&
        css`
            width: fit-content;
            padding: 16px 39px;
        `}
    `}
`;

const NoCompaniesText = styled.div`
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
`;

type Props = {
    articleData?: ArticlesPreview[];
    isMedia?: boolean;
    mediaData?: {
        buttonText?: string;
    };
};
export const ArticlesAndSidebar = (props: Props) => {
    const { bannersList, screenViewPort } = useSelector((state: IState) => state.global);
    const [t] = useTranslation();

    const formattedBanners = () => {
        const list = [];
        if (bannersList.WBQ.length > 0) {
            list.push(bannersList.WBQ[0]);
        }
        if (bannersList.WBM.length > 0) {
            list.push(...bannersList.WBM.slice(0, 3));
        }
        if (bannersList.WBC.length > 0) {
            list.push(bannersList.WBC[0]);
        }
        if (bannersList.WBD.length > 0) {
            list.push(bannersList.WBD[0]);
        }
        return list;
    };

    return (
        <ArticlesAndSidebarWrap>
            <Hero>
                {props.articleData && props.articleData.length > 0 ? (
                    <>
                        <ArticleWrap>
                            {props.articleData &&
                                props.articleData.map((article, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ArticleCard
                                                key={article.id}
                                                {...article}
                                                mediaData={props.mediaData}
                                                media={props.isMedia}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                        </ArticleWrap>
                    </>
                ) : (
                    <NoCompaniesText>{!props.isMedia ? t('no_articles') : t('no_press_releases')}</NoCompaniesText>
                )}
            </Hero>
            <ExpandedContainer>
                <AdsWrapper>
                    {formattedBanners().map((banner) => {
                        return (
                            <AdCard
                                key={banner.id}
                                adUrl={banner.image}
                                adHref={banner.url}
                                customDimensions={
                                    screenViewPort === 'desktop'
                                        ? { height: 366, width: 366 }
                                        : { height: 250, width: 250 }
                                }
                            />
                        );
                    })}
                </AdsWrapper>
            </ExpandedContainer>
        </ArticlesAndSidebarWrap>
    );
};
