import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { getIndividualCompany } from '../../services/company.service';
import { setBreadcrumb } from '../../store/global.slice';
import { useSelector } from 'react-redux';
import { IState } from '../../store';
import { ArticlesPreview, CompanyData } from '../../types/api';
import { Head } from '../../components/InnerCardPage/components/Head/Head';
import { InformationAndEmployees } from '../../components/InnerCardPage/InformationAndEmployees';
import { getArticlesList } from '../../services/articles.service';
import { useTranslation } from 'react-i18next';
import { ArticlesYouMightLikeCard } from '../../components/ArticlesYouMightLike/ArticlesYouMightLikeCard';
import { getMediaList } from '../../services/media.service';
import { MediaCard } from '../../components/MediaCard/MediaCard';
import classNames from 'classnames';

const BusinessCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
`;
const BusinessCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;
const Line = styled.div`
    ${(props) => css`
        height: 1px;
        width: 500%;
        align-self: center;
        background-color: ${props.theme.colors.outlineGrey};
    `}
`;

const SimilarArticles = styled.div`
    ${(props) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: ${props.theme.colors.white};
        width: 100%;
        &.gate-active {
            scroll-margin-top: 270px;
        }
    `}
`;

const ArticleWrap = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 30px;
        ${!props.theme.screen.desktop &&
        css`
            grid-template-columns: 1fr 1fr;
        `}
    `}
`;
const ArticlesHeader = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.01em;
    margin: 32px 0px 24px;
    &.media {
        margin-top: 0px;
    }
`;
const Wrap = styled.div`
    width: 1254px;
    margin: 0 16px 48px;
`;

export const InnerCompanyPage = () => {
    const [companyData, setCompanyData] = useState<CompanyData>();
    const [articles, setArticles] = useState<ArticlesPreview[]>([]);
    const [mediaArticles, setMediaArticles] = useState<ArticlesPreview[]>([]);
    const { t } = useTranslation();

    const { screenViewPort, gateIsActive } = useSelector((state: IState) => state.global);

    const urlParams = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (urlParams.companyId) {
            getIndividualCompany(parseInt(urlParams.companyId)).then((response: CompanyData) => {
                setCompanyData(response);
                dispatch(
                    setBreadcrumb({
                        key: 'companyName',
                        value: response.name,
                    })
                );
                const companyTopicsIds = response.topics.map((topic) => topic.id);
                if (companyTopicsIds.length > 0) {
                    const companyTopicsIdsString = companyTopicsIds?.join(',');
                    const idString = `&topics=${companyTopicsIdsString}`;

                    getArticlesList(1, idString).then((res) => {
                        const articlesWithContent = res.data.filter(
                            (article: ArticlesPreview) => article.contentTeaser
                        );
                        const companiesArticles: ArticlesPreview[] = [];
                        articlesWithContent.forEach((article) => {
                            if (article.companyId.toString() === urlParams.companyId) {
                                companiesArticles.push(article);
                            }
                        });
                        if (companiesArticles.length > 0) {
                            setArticles(companiesArticles);
                        } else {
                            setArticles(articlesWithContent);
                        }
                    });
                }
            });
            getMediaList().then((resp) => {
                const mediaWithContent = resp.data.filter(
                    (article) => article.contentTeaser && article.company.id.toString() === urlParams.companyId
                );
                setMediaArticles(mediaWithContent);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams?.companyId]);

    return (
        <>
            {companyData && (
                <BusinessCardContainer>
                    <BusinessCard>
                        <Head data={companyData} articlesLength={articles.length} />
                        {screenViewPort !== 'mobile' && <Line />}
                        <InformationAndEmployees data={companyData} />
                    </BusinessCard>
                    <SimilarArticles id="articles" className={classNames({ 'gate-active': gateIsActive })}>
                        <Wrap>
                            <ArticlesHeader>{t('articles')}</ArticlesHeader>
                            {articles.length > 0 ? (
                                <ArticleWrap>
                                    {articles &&
                                        articles.map((article, index: number) => {
                                            return (
                                                index < 4 && <ArticlesYouMightLikeCard key={article.id} {...article} />
                                            );
                                        })}
                                </ArticleWrap>
                            ) : (
                                t('no_articles')
                            )}
                        </Wrap>
                    </SimilarArticles>
                    {mediaArticles.length > 0 && (
                        <SimilarArticles id="press-releases">
                            <Wrap>
                                <ArticlesHeader className="media">{t('press_releases')}</ArticlesHeader>
                                <ArticleWrap>
                                    {mediaArticles &&
                                        mediaArticles.map((mediaArticle, index: number) => {
                                            return index < 4 && <MediaCard key={mediaArticle.id} {...mediaArticle} />;
                                        })}
                                </ArticleWrap>
                            </Wrap>
                        </SimilarArticles>
                    )}
                </BusinessCardContainer>
            )}
        </>
    );
};
