import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const CustomSlider = styled(Slider)(({ theme }) => ({
    color: '#000',
    height: 3,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
        height: 13,
        width: 13,
        backgroundColor: '#000',
        boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
        '&:before': {
            boxShadow:
                '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: 12,
        fontWeight: 'normal',
        top: -6,
        backgroundColor: 'unset',
        border: '1px solid #000',
        borderRadius: '4px',
        '&::before': {
            display: 'none',
        },
        '& *': {
            background: 'transparent',
            color: '#7E7E7E',
        },
    },
    '& .MuiSlider-track': {
        border: 'none',
        height: 4,
    },
    '& .MuiSlider-rail': {
        boxShadow: 'inset 0px 0px 4px -2px #000',
        backgroundColor: '#d0d0d0',
    },
}));

type Props = {
    label: string;
    value: number | number[] | undefined;
    onChange: (event: Event, value: number | number[], activeThumb: number) => void;
};

export default function SliderItem(props: Props) {
    return (
        <Box sx={{ padding: '0 30px' }}>
            <Typography sx={{ marginBottom: '30px', color: '#7E7E7E' }}>{props.label}</Typography>
            <CustomSlider
                valueLabelDisplay="on"
                size="small"
                getAriaLabel={() => 'Numeric Stats'}
                disableSwap
                min={0}
                max={10000000}
                step={10000}
                onChange={props.onChange}
                value={props.value}
            />
        </Box>
    );
}
