import { useState } from 'react';
import styled, { css } from 'styled-components';
import southIcon from '@mui/icons-material/South';
import { useTranslation } from 'react-i18next';
import { BankAccount } from '../../../../../types/api';

const BankAccountsContainer = styled.div`
    ${(props) => css`
        margin-top: 24px;
        border-radius: 8px;
        background-color: ${props.theme.colors.white};
        padding: 16px;
        box-shadow: 0px 4px 15px 0px ${props.theme.colors.shadow};
    `}
`;
const Header = styled.div`
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 24px;
`;
const Accounts = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: 1fr;
        `}
    `}
`;

const Account = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.greyBg};
        border-radius: 8px;
        padding: 16px;
    `}
`;
const Name = styled.div`
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 8px;
`;
const Text = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
`;

const Button = styled.div`
    ${(props) => css`
        margin-top: 26px;
        display: flex;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: ${props.theme.colors.green};
        width: fit-content;
        align-items: center;
        cursor: pointer;
        position: relative;
        margin-bottom: 2px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 12px;
            line-height: 14px;
        `}
    `}
`;

const SouthIcon = styled(southIcon)`
    ${(props) => css`
        color: ${props.theme.colors.green};
        margin-left: 8px;
        height: 18px !important;
        width: 18px !important;
        rotate: 270deg;
        &.show-more {
            rotate: 180deg;
        }
    `}
`;
const ShowMoreText = styled.div`
    display: flex;
    flex-wrap: wrap;
    &:hover {
        font-weight: 600;
        &:after {
            content: '';
            margin-top: -1px;
            transform: translateY(4px);
            height: 2px;
            display: flex;
            width: 52%;
            margin-left: 24%;
            align-items: center;
            justify-content: center;
            background-color: ${(props) => props.theme.colors.green};
        }
    }
`;

type BankAcc = {
    bankAccounts: BankAccount[];
};

export const BankAccounts = (props: BankAcc) => {
    const [showMore, setShowMore] = useState(false);
    const { t } = useTranslation();

    const toggleShowMore = () => {
        setShowMore((current) => !current);
    };

    const accountSlice = (branches: BankAccount[]) => {
        return branches && branches.length > 2 && !showMore ? branches.slice(0, 2) : branches;
    };

    return (
        <>
            {props.bankAccounts.length > 0 && (
                <BankAccountsContainer>
                    <Header>{t('bank_accounts')}</Header>
                    <Accounts>
                        {accountSlice(props.bankAccounts).map((account, index) => {
                            return (
                                <Account key={index}>
                                    <Name>{account.bankName}</Name>
                                    <Text>{account.accountNumber}</Text>
                                </Account>
                            );
                        })}
                    </Accounts>
                    {props.bankAccounts.length > 2 && (
                        <Button onClick={toggleShowMore}>
                            <ShowMoreText>{!showMore ? t('all_bank_accounts') : t('less_bank_accounts')}</ShowMoreText>
                            <SouthIcon className={showMore ? 'show-more' : ''} />
                        </Button>
                    )}
                </BankAccountsContainer>
            )}
        </>
    );
};
