import { slide as Menu } from 'react-burger-menu';
import { ReactComponent as Logo } from '../../assets/images/Header/visa_lietuva_logo.svg';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { ROUTE_NAMES } from '../../constants/routes';
import { useState } from 'react';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../store';
import { setLoginModalOpen } from '../../store/global.slice';
import personIcon from '@mui/icons-material/Person';
import { LoginModal } from '../LoginModal/LoginModal';
import { UserProfile } from './UserProfile';

const BurgerMenuContainer = styled.div`
    ${(props) => css`
        /* To hide burger menu bars under mobile search module */
        position: absolute;
        z-index: 1301;
        .bm-menu-wrap {
            top: 0;
            left: 0;
        }
        .bm-burger-button {
            position: fixed;
            width: 18px;
            height: 12px;
            right: 16px;
            top: 30px;
        }
        .bm-overlay {
            top: 0px;
            left: 0px;
        }
        .bm-menu {
            background: ${props.theme.colors.darkGreen};
            font-size: 1.15em;
        }
        .bm-item {
            padding: 16px 16px 0;
            color: ${props.theme.colors.white};
            &.padding-sides {
                padding: 0 16px;
            }
            &.logo-container {
                padding: 0 16px;
                height: 72px;
                display: flex !important;
                align-items: center;
            }
        }
        .bm-cross-button {
            position: relative;
            top: 24px !important;
            right: 13px !important;
        }
        .bm-cross {
            background-color: ${props.theme.colors.white};
        }
        .bm-burger-bars {
            background-color: ${props.theme.colors.white};
        }
        .bm-cross.cross-line {
            background-color: ${props.theme.colors.white};
            width: 2px !important;
            height: 17px !important;
        }
        .languages {
            display: flex !important;
            justify-content: center;
        }
    `}
`;
const BusinessLogo = styled(Logo)`
    height: 22px;
    width: 161px;
    align-self: center;
`;
const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const NavigationLink = styled(NavLink)`
    ${(props) => css`
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-decoration: none;
        margin-bottom: 16px;
        color: ${props.theme.colors.white};
        &:hover {
            color: ${props.theme.colors.green};
        }
    `}
`;
const Divider = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.middleGreen};
        width: calc(100% - 32px);
        height: 1px;
        padding: 0px;
    `}
`;

const ActionButton = styled.div`
    ${(props) => css`
        display: flex !important;
        padding: 16px 16px 0px;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        margin-bottom: 16px;
        color: ${props.theme.colors.white};
        &:hover {
            color: ${props.theme.colors.green};
        }
    `}
`;

const PersonIcon = styled(personIcon)`
    width: 18px !important;
    height: 18px !important;
    margin-right: 8px;
`;

const ProfileWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const BurgerMenu = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { loginModalOpen } = useSelector((state: IState) => state.global);
    const { me } = useSelector((state: IState) => state.user);

    const [isOpen, setOpen] = useState(false);
    const [userAction, setUserAction] = useState<string>('');

    const closeSideBar = () => {
        setOpen(false);
    };

    const handleAction = (action: string) => {
        setUserAction(action);
        dispatch(setLoginModalOpen(true));
        closeSideBar();
    };

    return (
        <BurgerMenuContainer>
            {loginModalOpen && <LoginModal isLoginOpen={loginModalOpen} selectedAction={userAction || 'login'} />}
            <Menu width={'100%'} isOpen={isOpen} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
                <Link to={ROUTE_NAMES.HOME} onClick={closeSideBar} className="logo-container">
                    <BusinessLogo className="business-logo" />
                </Link>
                {!me ? (
                    <>
                        <ActionButton onClick={() => handleAction('login')}>
                            <PersonIcon />
                            {t('login')}
                        </ActionButton>
                        <Divider className="padding-sides" />
                        <ActionButton onClick={() => handleAction('register')}>{t('register')}</ActionButton>
                        <Divider className="padding-sides" />
                    </>
                ) : (
                    <ProfileWrapper>
                        <UserProfile logoutAction={() => closeSideBar()} />
                    </ProfileWrapper>
                )}
                <NavigationLink to={ROUTE_NAMES.HOME} onClick={closeSideBar}>
                    {t('companies_catalogue')}
                </NavigationLink>
                <Divider className="padding-sides" />
                <NavigationLink to={'ROUTE_NAMES'} onClick={closeSideBar}>
                    {t('new_companies')}
                </NavigationLink>
                <Divider className="padding-sides" />
                <NavigationLink to={ROUTE_NAMES.ARTICLES_PAGE} onClick={closeSideBar}>
                    {t('articles')}
                </NavigationLink>
                <Divider className="padding-sides" />
                <NavigationLink to={ROUTE_NAMES.ARTICLES_PAGE} onClick={closeSideBar}>
                    {t('press_release')}
                </NavigationLink>
                <Divider className="padding-sides" />
                <ItemWrapper className="languages">
                    <LanguageSwitcher />
                </ItemWrapper>
            </Menu>
        </BurgerMenuContainer>
    );
};
