import L from 'leaflet';
import { TileLayer, Marker, Popup, MapContainer, useMap } from 'react-leaflet';
import icon from '../../assets/images/Leaflet/orange_pin.svg';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import 'leaflet/dist/leaflet.css';
import { useTranslation } from 'react-i18next';
import { Address, CompanyListItem } from '../../types/api';

const Map = styled.div`
    position: sticky;
    top: 91px;
    width: 100%;
    &.gate-active {
        top: 221px;
    }
`;
const FullMap = styled(MapContainer)`
    width: 100%;
    height: 80vh;
    &.height {
        height: 313px;
    }
`;

const DefaultIcon = L.icon({
    iconRetinaUrl: icon,
    iconUrl: icon,
});

L.Marker.prototype.options.icon = DefaultIcon;

type Cord = {
    lat: number;
    lng: number;
};

type Props = {
    coordinates?: Address;
    companies?: CompanyListItem[];
    leafletHeight?: string;
    zoom?: number;
};

const LeafletMap = (props: Props) => {
    const { t } = useTranslation();
    const [position, setPosition] = useState<Cord>({ lat: 54.6872, lng: 25.2797 }); // set default position

    useEffect(() => {
        setPosition((prev) => ({
            ...prev,
            lat: props.coordinates?.latitude || 54.6872,
            lng: props.coordinates?.longitude || 25.2797,
        }));
    }, [props.coordinates?.latitude, props.coordinates?.longitude]);

    const Recenter = ({ lat, lng }: { lat: number; lng: number }) => {
        const map = useMap();
        const withCords = props.companies?.filter((comp) => comp.address?.latitude && comp.address?.longitude) || [];
        const cordmap: L.Marker<any>[] | undefined = withCords.map((comp) => {
            return L.marker([comp.address.latitude, comp.address.longitude]);
        });

        useEffect(() => {
            if (cordmap && cordmap.length > 0 && props.coordinates) {
                const group = L.featureGroup([
                    ...cordmap,
                    L.marker([props.coordinates?.latitude, props.coordinates?.longitude]),
                ]);
                map.fitBounds(group.getBounds().pad(0.5));
            } else {
                map.setView([lat, lng]);
            }
            map.invalidateSize();
        }, [cordmap, lat, lng, map]);
        return null;
    };

    const isCoordinatesSet = (
        lat: number,
        lng: number,
        street?: string,
        buildingNum?: string,
        city?: string,
        name?: string
    ) => {
        if (!lat && !lng) {
            return <Popup>{t('company_location_not_specified')}</Popup>;
        }
        return (
            <Popup>
                {`${name ? name : ''} ${street} 
                ${buildingNum}
                ${city}`}
            </Popup>
        );
    };

    return (
        <Map>
            <FullMap center={[0, 0]} zoom={13} scrollWheelZoom={true} className={classNames(props.leafletHeight)}>
                <Recenter lat={position.lat || 54.6872} lng={position.lng || 25.2797} />
                <TileLayer
                    attribution='<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://api.mapbox.com/styles/v1/rokelis3/clb1yxoyc000814pnsfpegajg/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoicm9rZWxpczMiLCJhIjoiY2xiMXozM2JnMWdndzNwb3FsbzF6NHhhMSJ9.wr_zI3BDTQmEJMX-gVecfg"
                />
                <Marker position={[position.lat || 54.6872, position.lng || 25.2797]}>
                    {isCoordinatesSet(
                        position.lat,
                        position.lng,
                        props.coordinates?.street,
                        props.coordinates?.buildingNum,
                        props.coordinates?.city
                    )}
                </Marker>
                {props.companies &&
                    props.companies?.length > 0 &&
                    props.companies.map((company, i) => {
                        if (company.address?.latitude && company.address?.longitude)
                            return (
                                <Marker position={[company.address?.latitude, company.address?.longitude]} key={i}>
                                    {isCoordinatesSet(
                                        company.address.latitude,
                                        company.address.longitude,
                                        company.address.street,
                                        company.address.buildingNum,
                                        company.address.city,
                                        company.name
                                    )}
                                </Marker>
                            );
                        return null;
                    })}
            </FullMap>
        </Map>
    );
};

export default LeafletMap;
