import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Galleries } from '../../types/api';
import styled, { css } from 'styled-components';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useSelector } from 'react-redux';
import { IState } from '../../store';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const GalleryContainer = styled.div`
    ${(props) => css`
        margin: 24px 0;
        width: calc(100% - 32px);
        padding: 16px;
        background-color: ${props.theme.colors.white};
        border-radius: 8px;
        &.media-gallery {
            background-color: ${props.theme.colors.greyBg};
        }
    `}
`;
const Img = styled.img`
    height: 200px;
    width: 92%;
    margin: 0 4%;
    border-radius: 8px;
`;
const ImgSlider = styled(Slider)`
    width: 100%;
    position: relative;
    .slick-track {
        margin-left: 0;
    }
`;
const Header = styled.div`
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 24px;
`;
const Button = styled.button`
    ${(props) => css`
        background-color: rgba(255, 255, 255, 0.5);
        border: 1px solid ${props.theme.colors.secondaryBlack};
        border-radius: 50px;
        height: 50px;
        width: 50px;
        position: absolute;
        z-index: 1;
        top: 40%;
        left: 10px;
        &.inactive {
            border: 1px solid ${props.theme.colors.outlineGrey};
            .icon {
                color: ${props.theme.colors.outlineGrey};
            }
        }
        &.next {
            left: unset;
            right: 10px;
            rotate: 180deg;
        }
        .icon {
            transform: translateY(2px);
            color: ${props.theme.colors.secondaryBlack};
        }
    `}
`;

const Text = styled.div`
    text-align: left;
`;

type Props = {
    galleryArray?: Galleries[];
    arrayOfImages?: string[];
};

export const Gallery = (props: Props) => {
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();
    const slidesToShow = {
        desktop: 5,
        tablet: 3,
        mobile: 2,
    };

    const ArrowLeft = (sliderProps: any) => (
        <Button
            {...sliderProps}
            className={classNames({
                inactive: sliderProps.currentSlide === 0,
            })}
        >
            <ArrowBackIosNewIcon className="icon" />
        </Button>
    );
    const ArrowRight = (sliderProps: any) => (
        <Button
            {...sliderProps}
            className={classNames('next', {
                inactive: sliderProps.currentSlide === sliderProps.slideCount - slidesToShow[screenViewPort],
            })}
        >
            <ArrowBackIosNewIcon className="icon" />
        </Button>
    );

    const settings = {
        className: 'center',
        infinite: false,
        dots: false,
        centerPadding: '60px',
        slidesToShow: slidesToShow.desktop,
        swipeToSlide: true,
        prevArrow: <ArrowLeft />,
        nextArrow: <ArrowRight />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: slidesToShow.tablet,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 765,
                settings: {
                    slidesToShow: slidesToShow.mobile,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <GalleryContainer className={classNames({ 'media-gallery': props.arrayOfImages })}>
            <Header>{t('gallery')}</Header>
            {props.galleryArray && props.galleryArray.length > 0 && (
                <ImgSlider {...settings}>
                    {props.galleryArray.map((card, index) => (
                        <div key={index}>
                            <Img alt={card.meta} src={card.logoPath} data-index={index} />
                        </div>
                    ))}
                </ImgSlider>
            )}
            {props.arrayOfImages && props.arrayOfImages.length > 0 && (
                <ImgSlider {...settings}>
                    {props.arrayOfImages.map((card, index) => (
                        <div key={index}>
                            <Img src={card} data-index={index} />
                        </div>
                    ))}
                </ImgSlider>
            )}
        </GalleryContainer>
    );
};
