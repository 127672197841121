import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconById } from './IconById';

import { ICategory } from '../../types/api';

import styled, { css } from 'styled-components';
import eastIcon from '@mui/icons-material/East';

const Card = styled.div`
    background-color: ${(props) => props.theme.colors.greyBg};
    border-radius: 8px;
    display: flex;
    width: 100%;
    flex-direction: column;
    text-decoration: none;
    box-shadow: 0px 4px 15px rgba(33, 33, 33, 0.1);
    color: ${(props) => props.theme.colors.black};
`;
const TextContainer = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        margin: 16px 16px 16px 0;
        ${!props.theme.screen.desktop &&
        css`
            flex-direction: unset;
            flex-wrap: wrap;
        `}
    `}
`;
const Text = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    &:not(.category-name) {
        padding-left: 16px;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    &.category-name {
        font-size: 16px;
        font-weight: 600;
        font-family: 'Inter';
    }
`;

const Icon = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background-color: ${props.theme.colors.green};
        border-radius: 50%;
        margin: 18px 0 18px 16px;
        ${!props.theme.screen.desktop &&
        css`
            margin: 16px 0 16px 16px;
            width: 32px;
            height: 32px;
        `}
    `}
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 16px;
    width: 100%;
    border-radius: 8px 8px 0 0;
    padding: 10px 0;
    ${(props) =>
        css`
            background-color: ${props.theme.colors.darkGreen};
            color: ${props.theme.colors.white};
        `}
`;
const TopicsWrapper = styled.div`
    padding-top: 16px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 8px;
`;
const TopicsList = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        gap: 8px;
        ${!props.theme.screen.desktop &&
        css`
            gap: 24px;
        `}
    `}
`;
const ButtonWrapper = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        margin: 18px 0;
        color: ${props.theme.colors.green};
        ${!props.theme.screen.desktop &&
        css`
            margin: 26px 0;
        `}
    `}
`;
const Arrow = styled(eastIcon)`
    margin-left: 4px;
    font-size: 12px !important;
`;

interface Props {
    category: ICategory;
}

export const CategoryCard = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const namesSplit = props.category.name.split(',');

    const navigateToFullList = (id: { [key: string]: number }) => {
        const state = id;
        navigate('/imoniu-katalogas', { state });
    };

    const navigateToBusinessList = (topicId?: number, topicName?: string) => {
        let topic = topicId ? `/${topicId}` : '';
        const state = { topicOrHead: topicName };
        navigate(`imoniu-sarasas/${props.category.id}${topic}`, { state });
    };

    return (
        <Card>
            <Header onClick={() => navigateToBusinessList()}>
                <Icon>
                    <IconById id={props.category.id} customStyles={{ width: 24, height: 24 }} />
                </Icon>
                <TextContainer>
                    {namesSplit.map((name, i) => (
                        <Text key={i} className="category-name">
                            {i === 0 && name.trim().charAt(0).toLocaleUpperCase() + name.trim().slice(1)}
                            {i > 0 && name}
                            {i !== namesSplit.length - 1 && `,\xa0`}
                        </Text>
                    ))}
                </TextContainer>
            </Header>
            <TopicsWrapper>
                <TopicsList>
                    {props.category.topics.slice(0, 4).map((topic, i) => {
                        return (
                            <Text key={i} onClick={() => navigateToBusinessList(topic.id, topic.name)}>
                                {topic.name}
                            </Text>
                        );
                    })}
                </TopicsList>
                {props.category.topics.length > 0 && (
                    <ButtonWrapper>
                        <Text onClick={() => navigateToFullList({ categoryId: props.category.id })}>
                            {t('all_categories')}
                        </Text>

                        <Arrow />
                    </ButtonWrapper>
                )}
            </TopicsWrapper>
        </Card>
    );
};
