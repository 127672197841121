import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AdCard } from '../../components/AdCard/AdCard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { IState } from '../../store';
import { ICategory, Topic } from '../../types/api';

import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const Container = styled.div`
    width: calc(100% -32px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
    padding: 0 24px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 1254px;
`;

const Header = styled.h3`
    font-size: 32px;
    line-height: 38px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 600;
`;

const LetterCategories = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 94px;
    background-color: ${(props) => props.theme.colors.white};
    z-index: 123;
    width: calc(100% + 32px);
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            overflow-y: scroll;
            top: 73px;
            justify-content: start;
        `}
`;
const SingleLetterWrapper = styled.div`
    width: 48px;
    height: 48px;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            min-width: 48px;
        `}
`;
const SingleLetter = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: ${(props) => props.theme.colors.darkGreen};
    line-height: 22px;
    cursor: pointer;
    box-sizing: border-box;
    &:hover,
    &.active {
        padding-top: 2px;
        border-bottom: 2px solid ${(props) => props.theme.colors.darkGreen};
    }
`;

const Separator = styled.div`
    width: 100%;
    max-width: 1254px;
    height: 1px;
    background: ${(props) => props.theme.colors.outlineGrey};
    margin-bottom: 32px;
    position: sticky;
    top: 142px;
    z-index: 1;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            max-width: 100%;
            overflow: auto;
            top: 121px;
        `}
`;
const CardsContainer = styled.div`
    display: flex;
    max-width: 1254px;
    gap: 30px;
    width: 100%;
    justify-content: space-between;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            flex-direction: column;
        `}
`;

const CardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            justify-content: center;
            align-items: center;
        `}
`;

const Card = styled.div`
    display: flex;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(33, 33, 33, 0.1);
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            flex-direction: column;
            width: 100%;
        `}
`;

const CardLetter = styled.div`
    width: 75px;
    display: flex;
    justify-content: center;
    padding-top: 5px;
    border-radius: 8px 0px 0px 8px;
    font-size: 32px;
    font-weight: 500;
    ${(props) =>
        css`
            background-color: ${props.theme.colors.darkGreen};
            color: ${props.theme.colors.green};
        `}
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            width: calc(100% - 16px);
            border-radius: 8px 8px 0px 0px;
            justify-content: start;
            padding: 8px 0 8px 16px;
            align-items: center;
            font-size: 20px;
        `}
`;

const CardTopicsWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 16px;
    color: ${(props) => props.theme.colors.secondaryBlack};
`;

const AdsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.colors.greyBg};
    border-radius: 8px;
    padding: 16px;
    height: 100%;
    gap: 30px;
`;

const ArrowIcon = styled(KeyboardArrowDownIcon)`
    color: ${(props) => props.theme.colors.darkGreen};
    cursor: pointer;
    &.active {
        transform: rotate(180deg);
    }
`;

const TopicWrapper = styled.div`
    display: flex;
    gap: 8px;
`;

const TopicName = styled.div`
    &.active {
        font-weight: 600;
    }
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const CardTopic = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const TopicHeads = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.secondaryBlack};
    padding-left: 25px;
`;

const TopicHead = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const AllCategories = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const navigate = useNavigate();

    const { categoriesList } = useSelector((state: IState) => state.company);
    const { selectedLanguage } = useSelector((state: IState) => state.language);
    const { bannersList, screenViewPort, gateIsActive } = useSelector((state: IState) => state.global);

    const [activeLetter, setActiveLetter] = useState<string>('');
    const [expandedTopic, setExpandedTopic] = useState<number>(-1);
    const [categoriesAlpha, setCategoriesAlpha] = useState<{ [key: string]: Topic[] }>({});

    useEffect(() => {
        state?.categoryId
            ? setCategoriesAlpha(categoryTopicsByAlphabet(state.categoryId))
            : setCategoriesAlpha(categoryTopicsByAlphabet());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoriesList]);

    const categoryTopicsByAlphabet = (categoryId?: number) => {
        const categories = categoryId
            ? categoriesList.filter((category: ICategory) => category.id === categoryId)
            : categoriesList;
        const alphaObj: { [key: string]: Topic[] } = {};
        categories.forEach((category: ICategory) => {
            category.topics.forEach((topic: Topic) => {
                const firstLetter = topic.name[0].toUpperCase();
                if (!alphaObj[firstLetter]) {
                    alphaObj[firstLetter] = [];
                }
                alphaObj[firstLetter].push(topic);
            });
        });
        const ordered = Object.keys(alphaObj)
            .sort((a, b) => a.localeCompare(b, selectedLanguage, { sensitivity: 'base' }))
            .reduce((obj: { [key: string]: Topic[] }, key) => {
                obj[key] = alphaObj[key];
                return obj;
            }, {});
        return ordered;
    };

    const scrollToAlphabet = (letter: string) => () => {
        const element = document.getElementById(`${letter.charCodeAt(0)}`);
        if (element) {
            setActiveLetter(letter);
            element.style.scrollMargin = screenViewPort === 'desktop' && gateIsActive ? '280px' : '168px';
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleExpandedTopic = (topicId: number) => () => {
        setExpandedTopic(expandedTopic === topicId ? -1 : topicId);
    };

    const navigateToBusinessList = (topicId: number, headId?: number) => {
        let categoryId = '';
        if (state?.categoryId) {
            categoryId = state.categoryId;
        } else {
            const category = categoriesList.find((category: ICategory) =>
                category.topics.find((topic: Topic) => topic.id === topicId)
            );
            categoryId = category?.id.toString() || '';
        }
        navigate(`imoniu-sarasas/${categoryId}/${topicId}${headId ? `/${headId}` : ''}`);
    };

    const formattedBanners = () => {
        const list = [];
        if (bannersList.WBQ.length > 0) {
            list.push(bannersList.WBQ[0]);
        }
        if (bannersList.WBM.length > 0) {
            list.push(...bannersList.WBM.slice(0, 3));
        }
        if (bannersList.WBC.length > 0) {
            list.push(bannersList.WBC[0]);
        }
        if (bannersList.WBD.length > 0) {
            list.push(bannersList.WBD[0]);
        }
        return list;
    };

    return (
        <Container>
            <HeaderWrapper>
                <Header>{t('companies_in_lithuania')}</Header>
            </HeaderWrapper>
            <LetterCategories
                className={classNames({
                    'gate-styles': gateIsActive && screenViewPort === 'desktop',
                })}
            >
                {Object.keys(categoriesAlpha).map((letter, index) => (
                    <SingleLetterWrapper key={index}>
                        <SingleLetter
                            onClick={scrollToAlphabet(letter)}
                            className={classNames({
                                active: activeLetter === letter,
                            })}
                        >
                            {letter}
                        </SingleLetter>
                    </SingleLetterWrapper>
                ))}
            </LetterCategories>
            <Separator />
            <CardsContainer>
                <CardsWrapper>
                    {Object.keys(categoriesAlpha).map((letter) => {
                        return (
                            <Card key={letter} id={`${letter.charCodeAt(0)}`}>
                                <CardLetter>{letter}</CardLetter>
                                <CardTopicsWrapper>
                                    {categoriesAlpha[letter].map((topic) => {
                                        return (
                                            <CardTopic key={topic.id}>
                                                <TopicWrapper>
                                                    <ArrowIcon
                                                        style={{ height: 18, width: 18 }}
                                                        className={classNames({
                                                            active: expandedTopic === topic.id,
                                                        })}
                                                        onClick={handleExpandedTopic(topic.id)}
                                                    />
                                                    <TopicName
                                                        className={classNames({
                                                            active: expandedTopic === topic.id,
                                                        })}
                                                        onClick={() => navigateToBusinessList(topic.id)}
                                                    >
                                                        {topic.name}
                                                    </TopicName>
                                                </TopicWrapper>
                                                {topic.id === expandedTopic && (
                                                    <TopicHeads>
                                                        {topic.heads.map((head) => (
                                                            <TopicHead
                                                                key={head.id}
                                                                onClick={() =>
                                                                    navigateToBusinessList(topic.id, head.id)
                                                                }
                                                            >
                                                                {head.name}
                                                            </TopicHead>
                                                        ))}
                                                    </TopicHeads>
                                                )}
                                            </CardTopic>
                                        );
                                    })}
                                </CardTopicsWrapper>
                            </Card>
                        );
                    })}
                </CardsWrapper>
                {
                    <AdsWrapper>
                        {formattedBanners().map((banner) => (
                            <AdCard
                                key={banner.id}
                                adUrl={banner.image}
                                adHref={banner.url}
                                customDimensions={{ height: 366, width: 366 }}
                            />
                        ))}
                    </AdsWrapper>
                }
            </CardsContainer>
        </Container>
    );
};
