import { t } from 'i18next';
import styled, { css } from 'styled-components';
import { CompanyResponse } from '../../../../../types/api';

const CompanyCodesContainer = styled.div`
    ${(props) => css`
        display: flex;
        background-color: ${props.theme.colors.white};
        border-radius: 8px;
        box-shadow: 0px 4px 15px 0px ${props.theme.colors.shadow};
        ${props.theme.screen.mobile &&
        css`
            flex-wrap: wrap;
            background-color: ${props.theme.colors.white};
        `}
    `}
`;
const Wrap = styled.div``;

const Name = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: ${props.theme.colors.black};
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 17px;
        `}
    `}
`;
const Info = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-top: 8px;
        color: ${props.theme.colors.secondaryBlack};
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 17px;
        `}
    `}
`;
const TwoBlocks = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        width: 50%;
        border-radius: 8px;
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
            background-color: ${props.theme.colors.white};
        `}
    `}
`;
const Container = styled.div`
    ${(props) => css`
        width: 50%;
        background-color: ${props.theme.colors.white};
        padding: 16px 24px;
        border-radius: 8px;
        ${props.theme.screen.mobile &&
        css`
            padding: 16px;
            &.third {
            }
            &.second {
                padding-bottom: 8px;
            }
        `}
    `}
`;

export const CompanyCodes = (props: CompanyResponse) => {
    const companyCodeValidator = (code: string) => {
        if (code.length === 11 && +code[0] > 2 && +code[0] < 7) {
            return <Info>{t('no_information_provided')}</Info>;
        } else {
            return <Info>{props.data.companyCode}</Info>;
        }
    };

    return (
        <CompanyCodesContainer>
            {(props.data.companyCode || props.data.vatCode) && (
                <TwoBlocks className="first">
                    {props.data.companyCode && (
                        <Container className="first">
                            <Wrap>
                                <Name>{t('company_code')}</Name>
                                {props.data.companyCode && companyCodeValidator(props.data.companyCode)}
                            </Wrap>
                        </Container>
                    )}
                    {props.data.vatCode && (
                        <Container className="second">
                            <Wrap>
                                <Name>{t('vat_code')}</Name>
                                <Info>{props.data.vatCode}</Info>
                            </Wrap>
                        </Container>
                    )}
                </TwoBlocks>
            )}
            {(props.data.registrationDate || props.data.insurerCode) && (
                <TwoBlocks className="second">
                    {props.data.registrationDate && (
                        <Container className="third">
                            <Wrap>
                                <Name>{t('registration_date')}</Name>
                                <Info>{props.data.registrationDate}</Info>
                            </Wrap>
                        </Container>
                    )}
                    {props.data.insurerCode && (
                        <Container className="fourth">
                            <Wrap>
                                <Name>{t('health_insurer_code')}</Name>
                                <Info>{props.data.insurerCode}</Info>
                            </Wrap>
                        </Container>
                    )}
                </TwoBlocks>
            )}
        </CompanyCodesContainer>
    );
};
