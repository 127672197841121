import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ArticlesAndSidebar } from '../../components/Articles/ArticlesAndSidebar/ArticlesAndSidebar';
import { SearchAndFilter } from '../../components/Articles/SearchAndFilter/SearchAndFilter';
import { getArticlesList } from '../../services/articles.service';
import { ArticlesPreview } from '../../types/api';

const ArticlesContainer = styled.div`
    display: flex;
    justify-content: center;
`;
const Wrap = styled.div`
    width: 1254px;
    margin: 0 16px;
`;

const SearchAndFilterContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const ArticlesPage = () => {
    const [articles, setArticles] = useState<ArticlesPreview[]>([]);

    useEffect(() => {
        getArticlesList().then((resp) => {
            const articlesWithContent = resp.data.filter((article) => article.contentTeaser);
            setArticles(articlesWithContent);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SearchAndFilterContainer>
                <Wrap>
                    <SearchAndFilter setAllArticles={setArticles} />
                </Wrap>
            </SearchAndFilterContainer>
            <ArticlesContainer>
                <Wrap>
                    <ArticlesAndSidebar articleData={articles} />
                </Wrap>
            </ArticlesContainer>
        </>
    );
};
