import styled, { css } from 'styled-components';
import { t } from 'i18next';
import { ChildCompanies } from '../../../../../types/api';
import { useSelector } from 'react-redux';
import { IState } from '../../../../../store';
import { useState } from 'react';

import PinIcon from '@mui/icons-material/FmdGood';
import PhoneIcon from '@mui/icons-material/LocalPhone';
import eastIcon from '@mui/icons-material/East';
import { NumberFormater } from '../../../../NumberFormater/NumberFormater';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

const BranchesContainer = styled.div`
    ${(props) => css`
        width: 100%;
        margin-top: 24px;
        background-color: ${props.theme.colors.white};
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 48px;
        ${props.theme.screen.mobile &&
        css`
            margin-bottom: 24px;
        `}
    `}
`;
const Header = styled.div`
    ${(props) => css`
        margin-top: 15px;
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
        scroll-margin-top: 100px;
        &.gate-active {
            scroll-margin-top: 270px;
        }
        ${!props.theme.screen.desktop &&
        css`
            scroll-margin-top: 80px;
        `}
        ${props.theme.screen.mobile &&
        css`
            font-size: 18px;
            line-height: 22px;
        `}
    `}
`;
const BranchesWrap = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 8px;
        gap: 16px;
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: 1fr;
        `}
    `}
`;

const Branch = styled.div`
    ${(props) => css`
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        margin-bottom: 18px;
        display: flex;
        &.no-margin {
            margin-bottom: 0;
        }
        ${props.theme.screen.mobile &&
        css`
            display: block;
        `}
    `}
`;
const Name = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 17px;
        `}
    `}
`;
const Info = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-top: 8px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 17px;
        `}
        .icon {
            margin-right: 8px;
            width: 18px !important;
            color: ${props.theme.colors.green};
        }
    `}
`;
const BranchWrap = styled.div`
    ${(props) => css`
        flex-direction: column;
        width: 100%;
        &.grey {
            background-color: ${props.theme.colors.greyBg};
            border-radius: 8px;
            padding: 16px 16px 18px;
            width: calc(100% - 32px);
        }
    `}
`;
const Divider = styled.div`
    ${(props) => css`
        margin: 16px 0;
        background-color: ${props.theme.colors.outlineGrey};
        height: 1px;
        width: 100%;
        ${props.theme.screen.mobile &&
        css`
            width: unset;
        `}
    `}
`;
const InnerButtonWrap = styled.div`
    ${(props) => css`
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: ${props.theme.colors.green};
        width: fit-content;
        display: flex;
        align-items: center;
        cursor: pointer;
        &.branches-button {
            margin-top: 26px;
        }
    `}
`;
const InnerButton = styled.div`
    &:hover {
        font-weight: 600;
        &:after {
            transform: translateY(4px);
            margin-top: -1px;
            content: '';
            height: 2px;
            display: flex;
            width: 52%;
            margin-left: 24%;
            align-items: center;
            justify-content: center;
            background-color: ${(props) => props.theme.colors.green};
        }
    }
`;
const EastIcon = styled(eastIcon)`
    width: 18px !important;
    margin-left: 8px;
    &.show-more {
        rotate: 270deg;
    }
`;

type Props = {
    childCompanies: ChildCompanies[];
};

export const Branches = (props: Props) => {
    const { screenViewPort, gateIsActive } = useSelector((state: IState) => state.global);
    const [showMore, setShowMore] = useState(false);
    const navigate = useNavigate();

    const toggleShowMore = () => {
        setShowMore((current) => !current);
    };

    const branchesSlice = (branches: any) => {
        if (screenViewPort !== 'mobile' && branches && branches.length > 3 && !showMore) {
            return branches.slice(0, 3);
        }
        if (screenViewPort === 'mobile' && branches && branches.length > 2 && !showMore) {
            return branches.slice(0, 2);
        }
        return branches;
    };

    const showButton = () => {
        return (
            (screenViewPort !== 'mobile' && props.childCompanies.length > 3) ||
            (screenViewPort === 'mobile' && props.childCompanies.length > 2)
        );
    };

    const goToBranch = (branch: number) => {
        navigate(`/imones-kortele/${branch}`);
    };

    return (
        <>
            {props.childCompanies.length > 0 && (
                <>
                    <Header id="branches" className={classNames({ 'gate-active': gateIsActive })}>
                        {t('branches')}
                    </Header>
                    <BranchesContainer>
                        <BranchesWrap>
                            {branchesSlice(props.childCompanies).map((branch: ChildCompanies, index: number) => {
                                return (
                                    <BranchWrap className="grey" key={index}>
                                        <Branch key={index}>
                                            <BranchWrap>
                                                <Name>{branch.name}</Name>
                                                <Divider />
                                                <Info>
                                                    <PinIcon className="icon" />
                                                    {`${branch.address.street}${branch.address.street ? ',' : ''} ${
                                                        branch.address.buildingNum
                                                    }${branch.address.roomNum ? '-' : ''}${branch.address.roomNum}${
                                                        branch.address.buildingNum.trim() ? ',' : ''
                                                    } ${branch.address.city} ${branch.address.zip}${
                                                        branch.address.zip ? ',' : ''
                                                    } ${branch.address.state}${branch.address.state ? ',' : ''} ${
                                                        branch.address.country
                                                    }`}
                                                </Info>
                                                <Info>
                                                    <PhoneIcon className="icon" />
                                                    <NumberFormater number={branch.phone} />
                                                </Info>
                                            </BranchWrap>
                                        </Branch>
                                        <InnerButtonWrap onClick={() => goToBranch(branch.id)}>
                                            <InnerButton className="inner-button">{t('full_description')}</InnerButton>
                                            <EastIcon />
                                        </InnerButtonWrap>
                                    </BranchWrap>
                                );
                            })}
                        </BranchesWrap>
                        {showButton() && (
                            <InnerButtonWrap onClick={toggleShowMore} className="branches-button">
                                <InnerButton className="inner-button">
                                    {!showMore ? t('all_branches') : t('less_branches')}
                                </InnerButton>
                                <EastIcon className={showMore ? 'show-more' : ''} />
                            </InnerButtonWrap>
                        )}
                    </BranchesContainer>
                </>
            )}
        </>
    );
};
