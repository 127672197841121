import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ReactComponent as Logo } from '../../assets/images/Footer/visa_lietuva_footer.svg';
import { ReactComponent as euLogo } from '../../assets/images/Footer/eu_logo.svg';

import facebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { ROUTE_NAMES } from '../../constants/routes';

const FooterWrap = styled.div`
    ${(props) => css`
        z-index: 3;
        position: relative;
        display: flex;
        justify-content: center;
        height: 211px;
        background-color: ${props.theme.colors.darkGreen};
        ${!props.theme.screen.desktop &&
        css`
            height: fit-content;
        `}
    `}
`;
const FooterContainer = styled.div`
    ${(props) => css`
        display: flex;
        flex-wrap: wrap;
        width: 1254px;
        padding: 64px 16px 32px;
        color: ${props.theme.colors.white};
        ${!props.theme.screen.desktop &&
        css`
            padding: 24px 16px;
            display: flex;
            flex-direction: column;
        `}
    `}
`;
const PageLink = styled(Link)`
    ${(props) => css`
        color: ${props.theme.colors.white};
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 10px;
        &.add-margin {
            margin-bottom: 8px;
        }
    `}
`;
const LinksToOtherPages = styled.div`
    ${(props) => css`
        display: flex;
        width: 42%;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;
const Col = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        margin-left: 30px;
        gap: 8px;
        width: 50%;
        &.last-col {
            width: 100%;
            ${!props.theme.screen.desktop &&
            css`
                flex-direction: column-reverse;
                gap: 0;
            `}
        }
        ${!props.theme.screen.desktop &&
        css`
            margin-left: 0;
            gap: 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
        `}
    `}
`;
const Info = styled.div`
    ${(props) => css`
        width: 33%;
        ${!props.theme.screen.desktop &&
        css`
            width: 50%;
        `}
    `}
`;
const Row = styled.div`
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            margin: 24px 0;
        `}
    `}
`;
const Wrap = styled.div`
    ${(props) => css`
        display: flex;
        width: calc(100% - 37px);
        ${!props.theme.screen.desktop &&
        css`
            flex-wrap: wrap;
            width: 100%;
        `}
    `}
`;
const LinkWrap = styled.div``;
const FacebookIcon = styled(facebookIcon)`
    margin-right: 10px;
`;
const CompanyLogo = styled(Logo)`
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            margin-bottom: 24px;
        `}
    `}
`;
const EuLogo = styled(euLogo)`
    ${(props) => css`
        width: 313px;
        height: 132px;
        ${!props.theme.screen.desktop &&
        css`
            width: 156px;
            height: 66px;
            margin-top: 72px;
        `}
    `}
`;

const ImgLink = styled(Link)`
    ${(props) => css`
        color: ${props.theme.colors.white};
    `}
`;

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <FooterWrap>
            <FooterContainer>
                <CompanyLogo />
                <Wrap>
                    <LinksToOtherPages>
                        <Col>
                            <PageLink to={'https://wedeliver.lt/lt/privatumo-politika/'} target="_blank">
                                {t('privacy_policy')}
                            </PageLink>
                            <PageLink to={ROUTE_NAMES.ALL_CATEGORIES}>{t('companies_catalogue')}</PageLink>
                            <PageLink to={ROUTE_NAMES.EU_PROJECTS}>{t('EU_support')}</PageLink>
                        </Col>
                        <Col>
                            <PageLink to={'ROUTE_NAMES'}>{t('statistics')}</PageLink>
                            <PageLink to={'https://wedeliver.lt/'} target="_blank">
                                {t('about_us')}
                            </PageLink>
                            <PageLink to={'https://wedeliver.lt/lt/kontaktai/'} target="_blank">
                                {t('contacts')}
                            </PageLink>
                        </Col>
                    </LinksToOtherPages>
                    <Info>
                        <Col className="last-col">
                            <LinkWrap>
                                <PageLink to="tel:+37061387583" className="add-margin">
                                    <LocalPhoneIcon />
                                    +370 613 87583
                                </PageLink>
                                <PageLink to="mailto:info@wedeliver.lt" className="add-margin">
                                    <EmailIcon />
                                    info@wedeliver.lt
                                </PageLink>
                            </LinkWrap>
                            <Row>
                                <ImgLink to={'https://www.facebook.com/Wedeliveragency'} target="_blank">
                                    <FacebookIcon />
                                </ImgLink>
                                <ImgLink to={'https://linkedin.com/company/we-deliver-agency'} target="_blank">
                                    <LinkedInIcon />
                                </ImgLink>
                            </Row>
                        </Col>
                    </Info>
                    <EuLogo></EuLogo>
                </Wrap>
            </FooterContainer>
        </FooterWrap>
    );
};
