import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { IState } from '../../../../store';
import { Sodra } from './components/Sodra';
import { EmployeeAmount } from './components/EmployeeAmount';

import { FinancialStats, IWorkerStatistics } from '../../../../types/api';

import classNames from 'classnames';

import styled, { css } from 'styled-components';
import { AverageSallary } from './components/AverageSallary';

const Container = styled.div`
    width: 100%;
    height: 100%;
`;

const Header = styled.div`
    ${(props) => css`
        font-size: 22px;
        font-weight: 500;
        line-height: 29px;
        margin: 24px 0 16px 0;
        &.gate-active {
            scroll-margin-top: 270px;
        }
        ${!props.theme.screen.desktop &&
        css`
            scroll-margin-top: 80px;
        `}
        ${props.theme.screen.mobile &&
        css`
            font-size: 18px;
            line-height: 22px;
            margin-top: 24px;
        `}
    `}
`;

const ChartsWrapper = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.greyBg};
    `}
`;

const ChartCard = styled.div`
    ${(props) => css`
        margin-bottom: 16px;
        background-color: ${props.theme.colors.white};
        border-radius: 8px;
        padding: 16px;
        width: calc(100% - 32px);
    `}
`;

const ChartRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            flex-direction: column;
            height: 100%;
        `};
`;

interface Props {
    financialStats: FinancialStats;
    employeeStatistics: IWorkerStatistics;
}

export const Charts = (props: Props) => {
    const { gateIsActive } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();

    const [showMore, setShowMore] = useState<{ [key: string]: boolean }>({
        sodraDebt: false,
        employeeAmount: false,
        salaryMedian: false,
    });

    const handleShowMore = (type: string) => {
        setShowMore((prev) => ({ ...prev, [type]: !prev[type] }));
    };

    return (
        <Container>
            <Header id="employees" className={classNames({ 'gate-active': gateIsActive })}>
                {t('employees')}
            </Header>
            <ChartsWrapper>
                <ChartRow>
                    {props.employeeStatistics?.totalCount?.length > 0 && (
                        <ChartCard>
                            <EmployeeAmount
                                employeeStatistics={{
                                    ...props.employeeStatistics,
                                    totalCount: props.employeeStatistics.totalCount.slice(
                                        props.employeeStatistics.totalCount.length - 20,
                                        props.employeeStatistics.totalCount.length
                                    ),
                                }}
                                showMore={showMore.employeeAmount}
                                onShowMore={handleShowMore}
                            />
                        </ChartCard>
                    )}
                    {props.financialStats?.sodraDebt?.length > 0 && (
                        <ChartCard>
                            <Sodra
                                data={props.financialStats.sodraDebt.slice(0, 20)}
                                showMore={showMore.sodraDebt}
                                onShowMore={handleShowMore}
                            />
                        </ChartCard>
                    )}
                </ChartRow>
            </ChartsWrapper>
            <ChartsWrapper>
                <ChartRow>
                    {props.financialStats?.salaryMedian?.length > 0 && (
                        <ChartCard>
                            <AverageSallary
                                data={props.financialStats.salaryMedian.slice(0, 20)}
                                showMore={showMore.salaryMedian}
                                onShowMore={handleShowMore}
                            />
                        </ChartCard>
                    )}
                </ChartRow>
            </ChartsWrapper>
        </Container>
    );
};
