import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { setUser } from '../../store/user/user.slice';
import { setLoading } from '../../store/global.slice';
import { logoutUser } from '../../services/auth.service';

import { IState } from '../../store';

import classNames from 'classnames';
import styled, { css } from 'styled-components';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import storage from '../../commons/localStorage';

const ArrowIcon = styled(ArrowDropDownIcon)``;

const Container = styled.div`
    position: relative;
    margin-right: 24px;
    height: 42px;
    ${(props) => css`
        ${props.theme.screen.desktop &&
        css`
            gap: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            color: ${props.theme.colors.white};
            background-color: ${props.theme.colors.middleGreen};
        `}
        ${!props.theme.screen.desktop &&
        css`
            display: flex;
            align-items: center;
            justify-content: space-between;
        `}
    `}
    cursor: pointer;
    border-radius: 8px;
    &.dropdown-open {
        border-radius: 8px 8px 0 0;
        ${ArrowIcon} {
            transform: rotate(180deg);
        }
    }
`;

const ProfilePicture = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
`;

const Username = styled.div`
    font-weight: 700;
    font-size: 15px;
`;

const Dropdown = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 0 0 4px 4px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
`;

const DropdownItem = styled.div`
    padding: 18px 16px;
    font-size: 16px;
    ${(props) => css`
        color: ${props.theme.colors.black};
        &:hover {
            font-weight: 700;
            color: ${(props) => props.theme.colors.red};
        }
    `}
`;

const MobileLogout = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.red};
    font-size: 14px;
    font-weight: 500;
    text-shadow: 0 0 1px ${(props) => props.theme.colors.red};
    text-decoration: underline;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

interface Props {
    logoutAction?: () => void;
}

export const UserProfile = (props: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { me } = useSelector((state: IState) => state.user);
    const { screenViewPort } = useSelector((state: IState) => state.global);

    const location = useLocation();

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [name, lastName] = me.name.split(' ');
    const isHomePage = location.pathname === '/';

    const handleLogout = () => {
        if (screenViewPort !== 'desktop' && props.logoutAction) {
            props.logoutAction();
        }
        dispatch(setLoading(true));
        logoutUser()
            .then(() => {
                storage.removeTokenData();
                dispatch(setUser(null));
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    return (
        <>
            {screenViewPort === 'desktop' ? (
                <Container
                    onClick={() => setDropdownOpen((prev) => !prev)}
                    className={classNames({
                        'dropdown-open': dropdownOpen,
                        homepage: isHomePage,
                    })}
                >
                    <ProfilePicture src={me.profilePicture} alt="" />
                    <Username>
                        {name} {lastName[0]}.
                    </Username>
                    <ArrowIcon />
                    {dropdownOpen && (
                        <Dropdown>
                            <DropdownItem onClick={() => handleLogout()}>{t('logout')}</DropdownItem>
                        </Dropdown>
                    )}
                </Container>
            ) : (
                <Container>
                    <Wrapper>
                        <ProfilePicture src={me.profilePicture} alt="" />
                        <Username>
                            {name} {lastName[0]}.
                        </Username>
                    </Wrapper>
                    <MobileLogout onClick={() => handleLogout()}>{t('logout')}</MobileLogout>
                </Container>
            )}
        </>
    );
};
