import classNames from 'classnames';
import styled, { css } from 'styled-components';
import { CompanyListItem } from '../../../types/api';

const TitleContainer = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 16px;
        border-radius: 8px 8px 0 0;
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.darkGreen};
        ${props.theme.screen.mobile &&
        css`
            padding: 10px 8px;
        `}
        &.lext-active {
            background-color: ${props.theme.colors.darkerYellow};
        }
    `}
`;
const Title = styled.div`
    ${(props) => css`
        font-size: 20px;
        font-weight: 400;
        line-height: 19px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 17px;
            line-height: 20px;
        `}
    `}
`;
const Header = styled.div`
    display: flex;
    align-items: center;
`;
const Logo = styled.img`
    ${(props) => css`
        object-fit: contain;
        height: 36px;
        max-width: 60px;
        margin-right: 16px;
        border-radius: 8px;
        ${props.theme.screen.mobile &&
        css`
            height: 48px;
            max-width: 84px;
        `}
    `}
`;

interface Props {
    data: CompanyListItem;
}

export const CardTop = (props: Props) => {
    return (
        <>
            <TitleContainer className={classNames({ 'lext-active': props.data.lextIntegration })}>
                <Header>
                    {props.data.logo && <Logo src={props.data.logo.logoPath} />}
                    <Title>{props.data.name}</Title>
                </Header>
            </TitleContainer>
        </>
    );
};
