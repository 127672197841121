import styled, { css } from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../store';
import classNames from 'classnames';
import { InputItem } from './InputItem';
import { getSearchCompaniesList, getSearchFilters } from '../../services/company.service';
import { setSearchFilters } from '../../store/company/company.slice';

import { ReactComponent as GreenButton } from '../../assets/images/Buttons/green_inner_button.svg';
import { ReactComponent as GreenHoverButton } from '../../assets/images/Buttons/green_hover_button.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import closeIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import searchIcon from '@mui/icons-material/Search';
import locationOnIcon from '@mui/icons-material/LocationOn';
import { AutocompleteSelect } from './AutocompleteSelect';
import { QueryParams } from '../../types/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { AutocompleteInput } from './AutocompleteInput';
import { Box, CircularProgress } from '@mui/material';

const Container = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        padding-top: 88px;
        height: auto;
        margin-bottom: 0px;
        ${!props.theme.screen.desktop &&
        css`
            margin-bottom: 16px;
            padding: 68px 0 0 0;
        `}
        &.home-page-search {
            height: 324px;
        }
        &.sticky-search {
            width: 100%;
            position: sticky;
            top: 97px;
            background: ${props.theme.colors.darkGreen};
            z-index: 1300;
            padding-top: 0;
            ${!props.theme.screen.desktop &&
            css`
                top: 73px;
            `}
            .wrapper {
                margin: 16px 0 0 0;
                padding: 0;
                ${!props.theme.screen.desktop &&
                css`
                    margin: 0;
                `}
            }
            &.advanced-active {
                padding-bottom: 23px;
            }
            &:not(.advanced-active) {
                height: 106px;
                ${props.theme.screen.tablet &&
                css`
                    height: 86px;
                `}
                ${props.theme.screen.mobile &&
                css`
                    height: 76px;
                `}
            }
        }
        &.hide-search {
            display: none;
        }
    `}
`;
const SearchBarContainer = styled.div`
    ${(props) => css`
        width: 826px;
        height: fit-content;
        margin: 32px 24px;
        padding: 24px;
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
            padding: 16px;
            margin: 0;
            background-color: ${props.theme.colors.darkGreen};
        `};
    `}
`;
const SearchForm = styled.form``;
const SearchFieldset = styled.fieldset`
    border: none;
    padding: 0;
    &.search-not-landing-page {
        margin: 0 16px;
    }
`;
const Header = styled.div`
    ${(props) => css`
        display: flex;
        line-height: 29px;
        margin-bottom: 16px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 18px;
            line-height: 22px;
        `}
    `}
`;
const Slogan = styled.div`
    font-size: 36px;
    color: ${(props) => props.theme.colors.white};
    &.green {
        color: ${(props) => props.theme.colors.green};
    }
`;

const SearchWrap = styled.div`
    ${(props) => css`
        display: flex;
        border: 1px solid ${props.theme.colors.outlineGrey};
        border-radius: 8px;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            border: none;
        `}
    `}
`;
const Button = styled.button`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.darkGreen};
        border-radius: 0px 8px 8px 0px;
        border: none;
        min-width: 48px;
        font-weight: 400;
        height: 48px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &:hover {
            font-weight: 700;
        }
        &.filter-button {
            width: 198px;
            height: 58px;
            padding: 16px 10px;
            background-color: ${props.theme.colors.darkGreen};
            border-radius: 8px 0px 0px 8px;
            transition: 0.2s ease-in-out;
            &:hover {
                background: ${props.theme.colors.green};
                font-weight: 700;
            }
            ${props.theme.screen.mobile &&
            css`
                height: 48px;
                display: flex;
                justify-content: center;
                width: 56px;
                background-color: ${props.theme.colors.middleGreen};
            `}
        }
        &.additional-search-button {
            background-image: none;
            width: 100%;
            border-radius: 8px;
            color: ${props.theme.colors.white};
            margin-top: 16px;
            height: 48px;
            font-size: 15px;
            font-weight: 600;
            line-height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                background-color: ${props.theme.colors.green};
                color: ${props.theme.colors.black};
            }
            &.reset {
                background-color: ${props.theme.colors.white};
                color: ${props.theme.colors.black};
            }
            &.undo {
                background-color: ${props.theme.colors.darkGreen};
                height: fit-content;
                color: ${props.theme.colors.green};
                height: 48px;
                &.margin-bottom {
                    margin-bottom: 16px;
                }
                &:hover {
                    background-color: ${props.theme.colors.green};
                    color: ${props.theme.colors.darkGreen};
                }
            }
            ${props.theme.screen.mobile &&
            css`
                background-color: ${props.theme.colors.green};
                &:hover {
                    background-color: ${props.theme.colors.lightGreen};
                    color: ${props.theme.colors.black};
                }
            `}
        }
    `}
`;
const SelectsInputsBottom = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.greyBg};
        border: 4px solid ${props.theme.colors.green};
        margin-top: 16px;
        border-radius: 8px;
        padding: 16px;
        .form {
            ${!props.theme.screen.mobile &&
            css`
                height: 54px;
            `}
        }
        ${props.theme.screen.mobile &&
        css`
            margin-top: 8px;
        `}
    `}
`;
const OnlySelectsInputsWrap = styled.div`
    ${(props) => css`
        border-radius: 8px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: 1fr;
        `}
    `}
`;
const InputWrap = styled.div`
    ${(props) => css`
        display: flex;
        height: 58px;
        width: 100%;
        ${props.theme.screen.mobile &&
        css`
            height: 48px;
            width: 100%;
            border: 1px solid ${props.theme.colors.white};
            border-radius: 8px;
        `}
    `}
`;
const SelectWrap = styled.div`
    ${(props) => css`
        display: flex;
        position: relative;
        &.mobileScreen {
            display: none;
        }
        &.inputAdded {
            display: flex;
            ${props.theme.screen.mobile &&
            css`
                margin-top: 8px;
            `}
        }
    `}
`;
const MobileModule = styled.div`
    ${(props) => css`
        &.hide {
            opacity: 0;
            pointer-events: none;
        }
        opacity: 1;
        transition: all linear 0.15s;
        ${props.theme.screen.mobile &&
        css`
            position: fixed;
            top: 0;
            left: 0;
            /* We remove the height and width of the container paddings */
            width: calc(100% - 32px);
            height: calc(100% - 41px);
            margin-top: 0;
            border-radius: 0;
            /* Mobile burger menu z-index is 1301 */
            z-index: 1302;
            overflow: auto;
            &.mobile {
                background-color: ${props.theme.colors.black};
                padding: 25px 16px 16px 16px;
            }
        `}
    `}
`;
const MobileFilterHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const MobileHeader = styled.div`
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 25px;
`;
const MobileFilterText = styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
`;
const MobileFilterWrap = styled.div`
    color: ${(props) => props.theme.colors.white};
    height: auto;
`;
const ShowOnInput = styled.div`
    ${(props) => css`
        min-width: 305px;
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
        `}
    `}
`;
const MobileInputButtons = styled.div``;

const ArrowButton = styled.button`
    position: absolute;
    background-color: transparent;
    right: 0;
    border: 0;
    cursor: pointer;
    top: 10px;
    &.mobile-only {
        top: 4px;
    }
    .hovered-button {
        display: none;
    }
    &:hover {
        .hovered-button {
            display: block;
        }
        .main-button {
            display: none;
        }
    }
`;

const SearchInput = styled.div`
    width: 100%;
    position: relative;
`;
const Wrapper = styled.div`
    display: flex;
    width: 100%;
    ${(props) => css`
        ${props.theme.screen.desktop &&
        css`
            max-width: 323px;
            min-width: 323px;
        `}
    `}
`;

const CloseIcon = styled(closeIcon)`
    &.smaller {
        height: 18px;
        width: 18px;
        background-repeat: no-repeat;
        margin-right: 8px;
    }
`;

const Arrow = styled(KeyboardArrowDownIcon)`
    margin-left: 4px;
    font-size: 16px !important;
    &.rotate-arrow {
        transform: rotate(180deg);
    }
`;

const Divider = styled.div`
    ${(props) => css`
        position: absolute;
        height: 24px;
        width: 1px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${props.theme.colors.outlineGrey};
    `}
`;
const Wrap = styled.div`
    ${(props) => css`
        position: relative;
        width: 100%;
    `}
`;
const SearchIcon = styled(searchIcon)`
    ${(props) => css`
        position: absolute;
        z-index: 20;
        top: 15px;
        left: 16px;
        color: ${props.theme.colors.outlinesAndPlaceholders};
        ${props.theme.screen.mobile &&
        css`
            top: 12px;
        `}
    `}
`;
const LocationOnIcon = styled(locationOnIcon)`
    ${(props) => css`
        position: absolute;
        z-index: 20;
        top: 15px;
        left: 16px;
        color: ${props.theme.colors.outlinesAndPlaceholders};
        ${props.theme.screen.mobile &&
        css`
            top: 12px;
        `}
    `}
`;
const initialFilters = {
    name: '',
    manager: '',
    legal_type: '',
    company_code: '',
    vat_code: '',
    days: [],
    head: '',
    topic: 0,
    category: 0,
    address: '',
    city: 0,
    query: '',
};

interface DisabledFilters {
    advancedTopic: boolean;
}

export const SearchBar = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { searchFilters, categoriesList } = useSelector((state: IState) => state.company);
    const { screenViewPort, loading, isSearchOpen } = useSelector((state: IState) => state.global);

    const [formKey, setFormKey] = useState(0);
    const [filters, setFilters] = useState<QueryParams>({ ...initialFilters });
    const [disabledFilters, setDisabledFilters] = useState<DisabledFilters>({
        advancedTopic: false,
    });
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [mobileInput, setMobileInput] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    const isLandingPage = location.pathname === '/';

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSet = (boolean: boolean) => {
        setMobileInput(boolean);
    };

    const setFilterValue = (filter: { [key: string]: string | number }) => {
        setFilters((prev) => ({ ...prev, ...filter }));
    };

    const searchForCompanies = () => {
        if (loading) {
            return;
        }
        getSearchCompaniesList(1, filters).then((res) => {
            setShowAdvanced(false);
            resetFilters();
            const state = { companies: res.data, filters: filters };
            if (typeof filters.topic === 'number') {
                const category = categoriesList.find((category) =>
                    category.topics.find((topic) => topic.id === filters.topic)
                );
                if (category) {
                    navigate(`imoniu-sarasas/${category.id}/${filters.topic}`, { state });
                    return;
                }
            }
            navigate('imoniu-sarasas', { state });
        });
    };

    const resetFilters = () => {
        setFormKey((prev) => prev + 1);
        setFilters({ ...initialFilters });
    };

    const handleQueryInput = (value: string | number) => {
        if (typeof value === 'number') {
            setFilterValue({ topic: value, query: '' });
            setDisabledFilters({ advancedTopic: true });
            return;
        }
        setDisabledFilters({ advancedTopic: false });
        setFilterValue({ query: value });
    };

    useEffect(() => {
        getSearchFilters().then((res) => {
            dispatch(setSearchFilters(res));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container
            ref={ref}
            className={classNames({
                'home-page-search': isLandingPage && screenViewPort === 'mobile',
                'sticky-search': !isLandingPage && isSearchOpen,
                'hide-search': !isLandingPage && !isSearchOpen,
                'advanced-active': showAdvanced,
            })}
        >
            <SearchBarContainer className="wrapper">
                <SearchForm
                    onSubmit={(e) => {
                        e.preventDefault();
                        searchForCompanies();
                    }}
                >
                    {isLandingPage && screenViewPort !== 'mobile' && (
                        <Header>
                            <Slogan className="green">{t('slogan.part_one')},&nbsp;</Slogan>
                            <Slogan>{t('slogan.part_two')}</Slogan>
                        </Header>
                    )}
                    <SearchFieldset className={classNames({ 'search-not-landing-page': !isLandingPage })}>
                        <SearchWrap>
                            <InputWrap>
                                <Button
                                    type="button"
                                    className="filter-button"
                                    onClick={() => setShowAdvanced((prev) => !prev)}
                                >
                                    <TuneIcon />
                                    {screenViewPort !== 'mobile' && (
                                        <>
                                            {t('advanced_search')}
                                            <Arrow
                                                className={classNames({
                                                    'rotate-arrow': showAdvanced,
                                                })}
                                            />
                                        </>
                                    )}
                                </Button>
                                <Wrapper>
                                    <SearchInput onClick={() => handleSet(true)}>
                                        <Wrap>
                                            <SearchIcon />
                                            <AutocompleteInput
                                                fieldKey={`query-${formKey}`}
                                                id="query_input"
                                                onChange={(value) => handleQueryInput(value)}
                                                options={searchFilters.topics}
                                                name={t('search_for_company')!}
                                                optionLabel="name"
                                                class={'main-select'}
                                                allowFreeText={true}
                                                disabled={loading}
                                            />
                                        </Wrap>
                                        {screenViewPort === 'mobile' && !mobileInput && (
                                            <ArrowButton className="input mobile-only">
                                                <GreenButton className={'main-button'} />
                                                <GreenHoverButton className={'hovered-button'} />
                                            </ArrowButton>
                                        )}
                                        <Divider />
                                    </SearchInput>
                                </Wrapper>
                            </InputWrap>
                            <ShowOnInput>
                                <SelectWrap
                                    className={classNames({
                                        mobileScreen: screenViewPort === 'mobile',
                                        inputAdded: mobileInput,
                                    })}
                                >
                                    <Wrap>
                                        {screenViewPort === 'mobile' && <LocationOnIcon />}
                                        <AutocompleteSelect
                                            fieldKey={`city-${formKey}`}
                                            class="main-select"
                                            name={t('city')}
                                            options={searchFilters?.city}
                                            onChange={(e) => setFilterValue({ city: e?.id || 0 })}
                                            optionLabel="city"
                                            matchFrom="start"
                                            id="choose_city"
                                            disabled={loading}
                                        />
                                    </Wrap>
                                    {screenViewPort !== 'mobile' && (
                                        <ArrowButton type="submit" className="input">
                                            <GreenButton className={'main-button'} />
                                            <GreenHoverButton className={'hovered-button'} />
                                        </ArrowButton>
                                    )}
                                </SelectWrap>
                                {screenViewPort === 'mobile' && mobileInput && (
                                    <MobileInputButtons>
                                        <Box sx={{ position: 'relative' }}>
                                            <Button
                                                className="additional-search-button"
                                                onClick={() => {
                                                    searchForCompanies();
                                                }}
                                            >
                                                {t('search')}
                                            </Button>
                                            {loading && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: 'white',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                        <Button
                                            className={classNames('additional-search-button', 'undo', {
                                                'margin-bottom': !isLandingPage,
                                            })}
                                            onClick={() => {
                                                handleSet(false);
                                            }}
                                        >
                                            <CloseIcon className="smaller" />
                                            {t('cancel')}
                                        </Button>
                                    </MobileInputButtons>
                                )}
                            </ShowOnInput>
                        </SearchWrap>
                        <MobileModule
                            className={classNames({
                                mobile: screenViewPort === 'mobile',
                                desktop: screenViewPort !== 'mobile',
                                hide: !showAdvanced,
                            })}
                        >
                            {screenViewPort === 'mobile' && (
                                <MobileFilterWrap>
                                    <MobileFilterHeaderContainer>
                                        <MobileHeader>{t('advanced_search')}</MobileHeader>
                                        <CloseIcon type="button" onClick={() => setShowAdvanced((prev) => !prev)} />
                                    </MobileFilterHeaderContainer>
                                    <MobileFilterText>{t('company_search_by')}</MobileFilterText>
                                </MobileFilterWrap>
                            )}
                            <SelectsInputsBottom>
                                <OnlySelectsInputsWrap>
                                    <InputItem
                                        class={classNames({ 'mobile-input-styles': screenViewPort === 'mobile' })}
                                        id="search_address_input"
                                        placeholder={t('address')!}
                                        value={filters.address}
                                        onChange={(value) => setFilterValue({ address: value })}
                                    />
                                    {/* TODO: implement worker_number filter when backend adds support for it */}
                                    {/* <SelectItem name={t('worker_number')} /> */}
                                    <AutocompleteSelect
                                        fieldKey={`area-${formKey}`}
                                        name={t('area_of_activity')}
                                        options={searchFilters?.topics}
                                        onChange={(e) => setFilterValue({ topic: e?.id || 0 })}
                                        optionLabel="name"
                                        id="area_of_activity"
                                        disabled={loading || disabledFilters.advancedTopic}
                                    />
                                    {/* TODO: Find out which filter specifically corresponds to 'brand' */}
                                    {/* <SelectItem name={t('brand')} /> */}
                                    <InputItem
                                        id="company_code_input"
                                        placeholder={t('company_code')!}
                                        value={filters.company_code}
                                        onChange={(value) => setFilterValue({ company_code: value || '' })}
                                    />
                                    <InputItem
                                        id="vat_code_input"
                                        placeholder={t('vat_code')!}
                                        value={filters.vat_code}
                                        onChange={(value) => setFilterValue({ vat_code: value || '' })}
                                    />
                                    <AutocompleteSelect
                                        fieldKey={`working-${formKey}`}
                                        name={t('working_hours')}
                                        options={searchFilters?.workingTime}
                                        onChange={(e) => setFilterValue({ days: e || [] })}
                                        multiple={true}
                                        optionLabel="name"
                                        id="choose_working_hours"
                                        disabled={loading}
                                    />
                                    <InputItem
                                        id="manager_input"
                                        placeholder={t('manager')!}
                                        value={filters.manager}
                                        onChange={(value) => setFilterValue({ manager: value || '' })}
                                    />
                                    <AutocompleteSelect
                                        fieldKey={`business-${formKey}`}
                                        name={t('company_type')}
                                        customTranslationKey="legal_types"
                                        options={Object.values(searchFilters?.legalType).filter((item) => item)}
                                        onChange={(e) => setFilterValue({ legal_type: e || '' })}
                                        id="choose_business_type"
                                        disabled={loading}
                                    />
                                </OnlySelectsInputsWrap>
                                {screenViewPort === 'mobile' && (
                                    <Button
                                        className="additional-search-button reset"
                                        onClick={() => {
                                            resetFilters();
                                        }}
                                    >
                                        {t('clear_all')}
                                    </Button>
                                )}
                                <Box sx={{ position: 'relative' }}>
                                    <Button
                                        className="additional-search-button"
                                        onClick={() => {
                                            searchForCompanies();
                                        }}
                                    >
                                        {t('search')}
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: 'white',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </SelectsInputsBottom>
                        </MobileModule>
                    </SearchFieldset>
                </SearchForm>
            </SearchBarContainer>
        </Container>
    );
};
