import styled, { css } from 'styled-components';
import { CompanyResponse } from '../../../../../types/api';
import eastIcon from '@mui/icons-material/East';
import emailIcon from '@mui/icons-material/Email';
import phoneIcon from '@mui/icons-material/Phone';
import { useTranslation } from 'react-i18next';
import { NumberFormater } from '../../../../NumberFormater/NumberFormater';
import { useState } from 'react';

const ManagerContainer = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.white};
        border-radius: 8px;
        padding: 16px;
        margin-top: 24px;
        box-shadow: 0px 4px 15px 0px ${props.theme.colors.shadow};
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 17px;
        `}
    `}
`;
const Text = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 16px;
`;
const Wrap = styled.div`
    ${(props) => css`
        margin-top: 24px;
        background-color: ${props.theme.colors.greyBg};
        border-radius: 8px;
    `}
`;
const Divider = styled.div`
    ${(props) => css`
        margin-left: 16px;
        width: calc(100% - 32px);
        height: 1px;
        background-color: ${props.theme.colors.outlineGrey};
    `}
`;
const Info = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        &.margin-top {
            margin-top: 8px;
            ${props.theme.screen.mobile &&
            css`
                margin-top: 25px;
            `}
        }
    `}
`;
const EmailIcon = styled(emailIcon)`
    ${(props) => css`
        width: 18px !important;
        margin-right: 8px;
        color: ${props.theme.colors.green};
    `}
`;
const PhoneIcon = styled(phoneIcon)`
    ${(props) => css`
        width: 18px !important;
        margin-right: 8px;
        color: ${props.theme.colors.green};
    `}
`;
const ButtonWrap = styled.div`
    ${(props) => css`
        padding: 0 16px 16px;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: ${props.theme.colors.green};
        width: fit-content;
        display: flex;
        align-items: center;
        cursor: pointer;
    `}
`;
const Button = styled.div`
    &:hover {
        font-weight: 600;
        &:after {
            transform: translateY(4px);
            margin-top: -1px;
            content: '';
            height: 2px;
            display: flex;
            width: 52%;
            margin-left: 24%;
            align-items: center;
            justify-content: center;
            background-color: ${(props) => props.theme.colors.green};
        }
    }
`;
const Arrow = styled(eastIcon)`
    width: 18px !important;
    margin-left: 8px;
`;

export const Manager = (props: CompanyResponse) => {
    const { t } = useTranslation();
    const [descriptionOpen, setDescriptionOpen] = useState(false);

    return (
        <ManagerContainer>
            <Header>{t('manager')}</Header>
            <Wrap>
                {props.data.manager && (
                    <>
                        <Text className="smaller-margin-top">{props.data.manager.name}</Text>
                    </>
                )}
                <Divider />
                {(props.data.email || props.data.phone) && (
                    <Text>
                        {props.data.email && (
                            <Info>
                                <EmailIcon />
                                {props.data.email}
                            </Info>
                        )}
                        {props.data.phone && (
                            <Info className="margin-top">
                                <PhoneIcon />
                                <NumberFormater number={props.data.phone} />
                            </Info>
                        )}
                    </Text>
                )}
                {(props.data.email || props.data.phone) && <Divider />}
                {props.data.description && descriptionOpen ? (
                    <Text>{props.data.description}</Text>
                ) : (
                    <Text>{props.data.description.split(' ').slice(0, 20).join(' ')}</Text>
                )}
                {props.data.description && props.data.description.split(' ').length > 20 && (
                    <ButtonWrap>
                        <Button
                            onClick={() => {
                                setDescriptionOpen((prev) => !prev);
                            }}
                        >
                            {descriptionOpen ? <>{t('short_description')}</> : <>{t('full_description')}</>}
                        </Button>
                        <Arrow />
                    </ButtonWrap>
                )}
            </Wrap>
        </ManagerContainer>
    );
};
