import { createSlice } from '@reduxjs/toolkit';
import { BannerItem } from '../types/api';
export interface IGlobalState {
    screenViewPort: 'desktop' | 'mobile' | 'tablet';
    gateIsActive: boolean;
    headerSearchOnScreen: boolean;
    isSearchOpen: boolean;
    loading: boolean;
    bannersList: BannersList;
    bannersFilteredByTopic: BannersFilteredByTopic;
    bannersFilteredByKeyword: BannersFilteredByKeyword;
    breadcrumbs: { [key: string]: string };
    loginModalOpen: boolean;
}

interface BannersList {
    WBC: BannerItem[];
    WBR: BannerItem[];
    WBS: BannerItem[];
    WBD: BannerItem[];
    WBZ: BannerItem[];
    WBM: BannerItem[];
    W005: BannerItem[];
    W001: BannerItem[];
    W003: BannerItem[];
    WBP: BannerItem[];
    WBQ: BannerItem[];
    WBZS: BannerItem[];
}

interface BannersFilteredByTopic {
    WBR: BannerItem[];
    WBS: BannerItem[];
    W001: BannerItem[];
}

interface BannersFilteredByKeyword {
    W003: BannerItem[];
    WBZ: BannerItem[];
    WBZS: BannerItem[];
}

const initialState: IGlobalState = {
    screenViewPort: 'desktop',
    gateIsActive: false,
    headerSearchOnScreen: true,
    isSearchOpen: false,
    loading: false,
    loginModalOpen: false,
    bannersList: {
        WBC: [],
        WBR: [],
        WBS: [],
        WBD: [],
        WBZ: [],
        WBM: [],
        W005: [],
        W001: [],
        W003: [],
        WBP: [],
        WBQ: [],
        WBZS: [],
    },
    bannersFilteredByTopic: {
        WBR: [],
        WBS: [],
        W001: [],
    },
    bannersFilteredByKeyword: {
        W003: [],
        WBZ: [],
        WBZS: [],
    },
    breadcrumbs: {},
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setScreenViewPort: (state, action) => {
            state.screenViewPort = action.payload;
        },
        setGateIsActive: (state, action) => {
            state.gateIsActive = action.payload;
        },
        setHeaderSearchOnScreen: (state, action) => {
            state.headerSearchOnScreen = action.payload;
        },
        setIsSearchOpen: (state, action) => {
            state.isSearchOpen = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setBannersList: (state, action) => {
            const formattedBanners: BannersList = {
                W001: [],
                W003: [],
                W005: [],
                WBR: [],
                WBZ: [],
                WBP: [],
                WBQ: [],
                WBS: [],
                WBZS: [],
                WBM: [],
                WBC: [],
                WBD: [],
            };
            action.payload.forEach((banner: BannerItem) => {
                const splitCode = banner.product?.split('(VL)');

                if (splitCode?.length > 0 && splitCode[0] in formattedBanners && banner.image && banner.currentActive) {
                    formattedBanners[splitCode[0] as keyof typeof formattedBanners].push(banner);
                }
            });

            state.bannersList = formattedBanners;
        },
        setBreadcrumb: (state, action) => {
            state.breadcrumbs[action.payload.key] = action.payload.value;
        },
        switchBannerPositions: (state) => {
            switchBanners(state.bannersList);
            switchBanners(state.bannersFilteredByTopic);
        },
        setLoginModalOpen: (state, action) => {
            state.loginModalOpen = action.payload;
        },
        setBannersByTopic: (state, action) => {
            const formattedBanners: BannersFilteredByTopic = {
                W001: [],
                WBR: [],
                WBS: [],
            };
            Object.keys(formattedBanners).forEach((key) => {
                const filteredList = state.bannersList[key as keyof typeof state.bannersList].filter((banner) =>
                    banner.topics?.find((topic) => `${topic.id}` === action.payload)
                );
                formattedBanners[key as keyof typeof formattedBanners] = filteredList;
            });

            state.bannersFilteredByTopic = formattedBanners;
        },
        setBannersByKeyword: (state, action) => {
            const formattedBanners: BannersFilteredByKeyword = {
                W003: [],
                WBZ: [],
                WBZS: [],
            };
            Object.keys(formattedBanners).forEach((key) => {
                const filteredList = state.bannersList[key as keyof typeof state.bannersList].filter((banner) => {
                    return banner.keywords?.find((keyword) => `${keyword.name}` === action.payload);
                });
                formattedBanners[key as keyof typeof formattedBanners] = filteredList;
            });

            state.bannersFilteredByKeyword = formattedBanners;
        },
    },
});

const switchBanners = (objectList: BannersList | BannersFilteredByTopic) => {
    Object.keys(objectList).forEach((key) => {
        const bannerList: BannerItem[] = objectList[key as keyof typeof objectList];
        bannerList.forEach((banner, index) => {
            if (bannerList[index + 1]) {
                objectList[key as keyof typeof objectList][index] = bannerList[index + 1];
                objectList[key as keyof typeof objectList][index + 1] = banner;
            }
        });
    });
};

export const {
    setScreenViewPort,
    setGateIsActive,
    setBannersList,
    setHeaderSearchOnScreen,
    setIsSearchOpen,
    setLoading,
    setBreadcrumb,
    switchBannerPositions,
    setLoginModalOpen,
    setBannersByTopic,
    setBannersByKeyword,
} = globalSlice.actions;

export default globalSlice.reducer;
