import { ReactComponent as StarImg } from '../../assets/images/BusinessListCard/star_icon.svg';
import { ReactComponent as EmptyStarImg } from '../../assets/images/BusinessListCard/empty_star.svg';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

const ReviewContainer = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        background-color: ${props.theme.colors.greyBg};
        width: fit-content;
        padding: 5px 10px;
        border-radius: 8px;
    `}
`;
const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`;
const Star = styled(StarImg)`
    ${(props) => css`
        width: 18px;
        height: 19px;
        margin-right: 4px;
        ${props.theme.screen.mobile &&
        css`
            &.bigger-stars {
                width: 14px;
                height: 14px;
            }
        `}
    `}
`;
const EmptyStar = styled(EmptyStarImg)`
    width: 18px;
    height: 19px;
    margin-right: 4px;
`;

const Rating = styled.div`
    display: flex;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    margin: 0 8px;
`;
const FeedbackNumber = styled.div`
    ${(props) => css`
        margin-left: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: ${props.theme.colors.outlinesAndPlaceholders};
    `}
`;
const Wrap = styled.div`
    min-width: 110px;
`;
interface Props {
    noNumber?: boolean;
    starClass?: string;
    rating: number;
    feedbackCount?: number;
}

export const Stars = (props: Props) => {
    const rating: number = props.rating;
    const { t } = useTranslation();

    return (
        <ReviewContainer>
            <Wrapper>
                <Wrap>
                    {Array.from(Array(5), (e, i) => {
                        if (Math.round(rating) <= i) {
                            return <EmptyStar key={i} />;
                        }
                        return <Star key={i} />;
                    })}
                </Wrap>
                <Rating>
                    {props.rating.toFixed(1)}
                    {t('stars_text')}
                    {props.feedbackCount === undefined ? '' : <FeedbackNumber>({props.feedbackCount})</FeedbackNumber>}
                </Rating>
            </Wrapper>
        </ReviewContainer>
    );
};
