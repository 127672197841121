import styled, { css } from 'styled-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ROUTE_NAMES } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import pageNotFound from '../../assets/images/PageNotFound/page_not_found.svg';
import { useEffect, useState } from 'react';
import { getSearchCompaniesList } from '../../services/company.service';

const PageNotFoundWrap = styled.div`
    ${(props) => css`
        position: relative;
        height: calc(100vh - 88px);
        width: 100%;
        overflow: hidden;
        padding-top: 88px;
        ${!props.theme.screen.desktop &&
        css`
            height: calc(100vh - 68px);
            padding-top: 68px;
        `}
    `}
`;
const Header = styled.div`
    text-align: center;
    padding-top: 112px;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0.01em;
`;
const Text = styled.div`
    text-align: center;
    padding-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
`;
const Button = styled.button`
    ${(props) => css`
        margin-top: 48px;
        border-radius: 8px;
        padding: 13px 24px 13px 24px;
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        border: none;
        background: ${props.theme.colors.darkGreen};
        color: ${props.theme.colors.white};
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &:hover {
            background: ${props.theme.colors.darkerYellow};
            box-shadow: 0px 3px 0px ${props.theme.colors.darkYellow};
            font-weight: 700;
        }
    `}
`;
const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;
const Content = styled.div`
    position: relative;
    z-index: 5;
`;
const BackGround = styled.div`
    ${(props) => css`
        position: absolute;
        background-image: url(${pageNotFound});
        height: 100%;
        width: 100%;
        z-index: 1;
        top: 0;
        background-size: cover;
        background-repeat: no-repeat;
        ${props.theme.screen.mobile &&
        css`
            background-position: 20%;
        `}
    `}
`;

export const PageNotFound = () => {
    const { t } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();

    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        getSearchCompaniesList(1, { query: location.pathname }).then((res) => {
            if (res.data.length > 0) {
                navigate(`/imones-kortele/${res.data[0].id}`);
            }

            setNotFound(true);
        });
    });

    return (
        <>
            {notFound && (
                <PageNotFoundWrap>
                    <BackGround />
                    <Content>
                        <Header>{t('page_not_found_header')}</Header>
                        <Text>{t('page_not_found_text')}</Text>
                        <ButtonWrap>
                            <Link to={ROUTE_NAMES.HOME}>
                                <Button type="button">{t('page_not_found_button')}</Button>
                            </Link>
                        </ButtonWrap>
                    </Content>
                </PageNotFoundWrap>
            )}
        </>
    );
};
