export const SELECTED_LANGUAGE_KEY = 'selected_language';

export enum Languages {
    LT = 'lt',
    EN = 'en',
    RU = 'ru',
}

export enum SiteLangID {
    LT = 7,
    EN = 8,
    RU = 9,
}
