import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { IState } from '../../store';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { BannerItem } from '../../types/api';

const GateContainer = styled.div`
    ${(props) => css`
        overflow: hidden;
        pointer-events: none;
        z-index: 2;
        display: flex;
        justify-content: center;
        position: sticky;
        height: 100vh;
        top: 97px;
        ${!props.theme.screen.desktop &&
        css`
            top: 69px;
        `}
    `}
`;
const GateWrap = styled.div`
    min-width: 1628px;
    height: 100%;
`;
const Gate = styled.img`
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 1254px;
`;

const CurtainWrapper = styled.div`
    position: sticky;
    top: 88px;
    z-index: 10;
    display: flex;
    justify-content: center;
    cursor: pointer;
`;
const CurtainRedirect = styled.div`
    position: absolute;
    &.top {
        width: 1628px;
        height: 154px;
    }
    &.left {
        transform: translateX(-732px);
        height: 100vh;
        width: 166px;
    }
    &.right {
        transform: translateX(732px);
        height: 100vh;
        width: 166px;
    }
`;

export const AdGate = () => {
    const { bannersList, bannersFilteredByTopic, bannersFilteredByKeyword } = useSelector(
        (state: IState) => state.global
    );
    const location = useLocation();
    const [banner, setBanner] = useState<BannerItem>();

    useEffect(() => {
        if (bannersFilteredByTopic.W001.length > 0) {
            setBanner(bannersFilteredByTopic.W001[0]);

            return;
        }

        if (bannersFilteredByKeyword.W003.length > 0) {
            setBanner(bannersFilteredByKeyword.W003[0]);

            return;
        }

        if (bannersList.W005.length > 0) {
            setBanner(bannersList.W005[0]);

            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersFilteredByTopic.W001, bannersFilteredByKeyword.W003, bannersList.W005]);

    const handleGateRedirect = () => {
        if (!banner) {
            return;
        }
        const url = banner.url;
        if (url) {
            window.open(url, '_blank');
        }
    };

    return (
        <>
            {banner && (
                <>
                    <CurtainWrapper>
                        <CurtainRedirect className="top" onClick={() => handleGateRedirect()} />
                        <CurtainRedirect className="left" onClick={() => handleGateRedirect()} />
                        <CurtainRedirect className="right" onClick={() => handleGateRedirect()} />
                    </CurtainWrapper>
                    <GateContainer>
                        <GateWrap>
                            <Gate src={banner.image} />
                        </GateWrap>
                    </GateContainer>
                </>
            )}
        </>
    );
};
