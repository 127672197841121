import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../../store/language/language.slice';
import localStorage from '../../commons/localStorage';

import { IState } from '../../store';
import { Languages, SELECTED_LANGUAGE_KEY, SiteLangID } from '../../constants/languages';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import styled from 'styled-components';
import { css } from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import React from 'react';

const LanguageWrap = styled.div`
    ${(props) => css`
        width: 40px;
        height: 92px;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        &:hover {
            color: ${props.theme.colors.green};
        }
        ${props.theme.screen.desktop &&
        css`
            &:hover:after {
                content: '';
                height: 2px;
                display: flex;
                width: 16px;
                position: relative;
                bottom: 38px;
                background-color: ${props.theme.colors.green};
                pointer-events: none;
            }
        `}
    `}
`;
const SelectedLanguage = styled.div`
    ${(props) => css`
        min-width: 40px;
        height: 92px;
        display: flex;
        align-items: center;
        ${!props.theme.screen.desktop &&
        css`
            display: block;
            min-width: 67px;
            height: unset;
            padding: 14px 16px;
        `}
    `}
`;
const LanguageDropdown = styled.div`
    ${(props) => css`
        position: absolute;
        background-color: ${props.theme.colors.white};
        top: 72px;
        transform: translateX(-12px);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        ${!props.theme.screen.desktop &&
        css`
            display: flex;
            top: unset;
            color: ${props.theme.colors.black};
            width: 99px;
            bottom: 0%;
            left: 16px;
            background-color: ${props.theme.colors.darkGreen};
        `}
    `}
`;
const LanguageOption = styled.div`
    ${(props) => css`
        padding: 16px;
        color: ${props.theme.colors.black};
        cursor: pointer;
        &:hover {
            color: ${props.theme.colors.green};
        }
        ${!props.theme.screen.desktop &&
        css`
            color: ${props.theme.colors.white};
            &:hover,
            &.active-language {
                color: ${props.theme.colors.green};
            }
        `}
    `}
`;
const LanguageSelect = styled.div`
    ${(props) => css`
        cursor: pointer;
        ${!props.theme.screen.desktop &&
        css`
            &.active {
                display: none;
            }
        `}
    `}
`;
const Wrap = styled.div`
    display: flex;
`;
const Underline = styled.div`
    ${(props) => css`
        display: none;
        height: 1px;
        width: 40px;
        background-color: ${props.theme.colors.black};
        transform: translateY(-25px);
        pointer-events: none;
        &.active {
            display: block;
        }
    `}
`;
const Icon = styled(ArrowDropDown)`
    ${(props) => css`
        pointer-events: none;
        width: 10px;
        transform: translate(-20px, 34px);
        &.active {
            rotate: 180deg;
            transform: translate(20px, -34px);
        }
    `}
`;

const fallbackLanguageList = [Languages.LT, Languages.EN, Languages.RU];

export const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const userSelectedLanguage = localStorage.get(SELECTED_LANGUAGE_KEY);
    const { selectedLanguage } = useSelector((state: IState) => state.language);
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const languageList = Object.keys(i18n?.services?.resourceStore?.data) || fallbackLanguageList;
    const navigate = useNavigate();
    const [languageModal, setLanguageModal] = useState(false);

    const switchLanguage = (locale: string) => {
        const upperLocale: any = locale.toUpperCase();

        localStorage.put(SELECTED_LANGUAGE_KEY, { [locale.toUpperCase()]: SiteLangID[upperLocale] });
        navigate(0); // refresh page to get data by the selected language
    };

    useEffect(() => {
        if (userSelectedLanguage) {
            const locale = Object.keys(userSelectedLanguage)[0].toLocaleLowerCase();
            dispatch(changeLanguage(locale));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleMouseOver = () => {
        setLanguageModal(true);
    };

    const handleMouseOut = () => {
        setLanguageModal(false);
    };

    return (
        <>
            {/* Hidden until further noitice */}
            <LanguageWrap className={classNames({ active: languageModal })}>
                <>
                    {screenViewPort === 'desktop' && (
                        <LanguageSelect className={classNames({ active: languageModal })}>
                            <Wrap>
                                <SelectedLanguage
                                    onClick={() => {
                                        if (screenViewPort === 'desktop') {
                                            setLanguageModal((state) => !state);
                                        }
                                    }}
                                    onMouseOver={handleMouseOver}
                                    onMouseOut={handleMouseOut}
                                    className={classNames({ active: languageModal })}
                                >
                                    {selectedLanguage.toLocaleUpperCase()}
                                </SelectedLanguage>
                                <Icon className={classNames({ active: languageModal })} />
                            </Wrap>
                            <Underline className={classNames({ active: languageModal })} />
                        </LanguageSelect>
                    )}
                    {(languageModal || screenViewPort !== 'desktop') && (
                        <LanguageDropdown onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                            {languageList.map((locale, i) => (
                                <React.Fragment key={i}>
                                    <LanguageOption
                                        onClick={() => switchLanguage(locale)}
                                        key={locale}
                                        className={classNames({ 'active-language': locale === selectedLanguage })}
                                    >
                                        {locale.toUpperCase()}
                                    </LanguageOption>
                                </React.Fragment>
                            ))}
                        </LanguageDropdown>
                    )}
                </>
            </LanguageWrap>
        </>
    );
};
