import styled, { css } from 'styled-components';
import eastIcon from '@mui/icons-material/East';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CompanyData, Topic } from '../../../types/api';
import { NumberFormater } from '../../NumberFormater/NumberFormater';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';

const CardContainer = styled.div`
    text-decoration: none;
    color: unset;
    display: flex;
    min-height: 296px;
`;
const CardWrap = styled.div`
    ${(props) => css`
        padding: 16px;
        width: 100%;
        background-color: ${props.theme.colors.white};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 8px;
        box-shadow: 0px 4px 15px 0px ${props.theme.colors.shadow};
    `}
`;
const Img = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 8px;
`;
const Category = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: ${props.theme.colors.green};
        margin-right: 8px;
        text-transform: uppercase;
        cursor: pointer;
        :hover {
            text-decoration: underline;
        }
        ${props.theme.screen.mobile &&
        css`
            font-size: 13px;
            line-height: 18px;
        `}
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        margin: 8px 0;
        word-break: break-word;
        ${props.theme.screen.mobile &&
        css`
            font-size: 17px;
            line-height: 20px;
            height: fit-content;
        `}
    `}
`;
const Date = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: ${props.theme.colors.secondaryBlack};
        margin-bottom: 8px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 16px;
        `}
    `}
`;
const Text = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: ${props.theme.colors.secondaryBlack};
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 17px;
            height: fit-content;
        `}
    `}
`;
const Button = styled(Link)`
    ${(props) => css`
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        margin-top: 18px;
        color: ${props.theme.colors.green};
        width: fit-content;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        text-decoration: none;
        ${props.theme.screen.mobile &&
        css`
            position: unset;
        `};
        &:hover {
            .active {
                font-weight: 600;
                transform: translateY(1px);
                &:after {
                    content: '';
                    height: 2px;
                    display: flex;
                    width: 52%;
                    margin-left: 24%;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    top: 4px;
                    background-color: ${(props) => props.theme.colors.green};
                }
            }
        }
    `}
`;
const TextWrap = styled.div``;
const ButtonWrap = styled.div`
    width: fit-content;
    height: 28px;
`;
const EastIcon = styled(eastIcon)`
    color: ${(props) => props.theme.colors.darkYellow};
    margin-left: 8px;
`;
const ImgWrap = styled.div`
    ${(props) => css`
        width: 50%;
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
            margin-bottom: 16px;
        `};
    `}
`;
const InfoWrap = styled.div`
    ${(props) => css`
        width: 50%;
        min-height: 264px;
        position: relative;
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
            min-height: unset;
        `};
    `}
`;
const Wrap = styled.div`
    ${(props) => css`
        display: flex;
        gap: 30px;
        ${props.theme.screen.mobile &&
        css`
            display: block;
        `};
    `}
`;
const CategoriesWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
`;
const MediaContainer = styled.div`
    ${(props) => css`
        width: 50%;
        border-right: 1px solid ${props.theme.colors.outlineGrey};
        ${props.theme.screen.mobile &&
        css`
            border-right: none;
            border-bottom: 1px solid ${props.theme.colors.outlineGrey};
            width: 100%;
            margin-bottom: 16px;
            padding-bottom: 9px;
        `};
    `}
`;
const Logo = styled.img`
    ${(props) => css`
        max-width: 226px;
        height: 70px;
        margin-bottom: 16px;
        ${props.theme.screen.mobile &&
        css`
            max-width: 165px;
            height: 51px;
        `};
    `};
`;
const MediaText = styled.a`
    ${(props) => css`
        width: fit-content;
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: ${props.theme.colors.secondaryBlack};
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        .icon {
            width: 18px !important;
            color: ${props.theme.colors.green};
            margin-right: 8px;
        }
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 17px;
            &.icon-text {
                margin-top: 9px;
            }
        `};
    `};
`;

type Props = {
    media?: boolean;
    company: CompanyData;
    id: number;
    name: string;
    postDate: string;
    banner: string | null;
    contentTeaser: string;
    topics: Topic[];
    mediaData?: {
        buttonText?: string;
    };
};
export const ArticleCard = (props: Props) => {
    const navigate = useNavigate();
    const { categoriesList } = useSelector((state: IState) => state.company);
    const { t } = useTranslation();

    const url = () => {
        if (props.media) {
            return `/pranesimai-spaudai/${props.id}`;
        } else {
            return `/straipsniai/${props.id}`;
        }
    };

    const navigateToTopic = (topicId: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const category = categoriesList.find((category) => category.topics.find((topic) => topic.id === topicId));
        if (category) {
            navigate(`/imoniu-sarasas/${category.id}/${topicId}`);
        }
    };

    const lastInArray = (topic: string, i: number) => {
        if (i === props.topics?.length - 1) {
            return topic;
        } else {
            return `${topic},`;
        }
    };

    return (
        <CardContainer>
            <CardWrap>
                <Wrap>
                    {props.media ? (
                        <MediaContainer>
                            {props.company.logo && <Logo src={props.company.logo.logoPath} />}
                            {props.company.manager && (
                                <MediaText>{`${props.company.manager.name}, ${props.company.manager.position}`}</MediaText>
                            )}
                            <MediaText>{`${props.company.name}, ${props.company.companyLegalType}`}</MediaText>
                            <MediaText
                                className="icon-text"
                                onClick={(e) => e.stopPropagation()}
                                href={`mailto:${props.company.email}`}
                            >
                                <EmailIcon className="icon" />
                                {props.company.email}
                            </MediaText>
                            <MediaText
                                className="icon-text"
                                onClick={(e) => e.stopPropagation()}
                                href={`tel:${props.company.email}`}
                            >
                                <LocalPhoneIcon className="icon" />
                                <NumberFormater number={props.company.phone} />
                            </MediaText>
                        </MediaContainer>
                    ) : (
                        <ImgWrap>{props.banner && <Img src={props.banner} />}</ImgWrap>
                    )}
                    <InfoWrap>
                        {props.topics?.length > 0 && !props.media && (
                            <CategoriesWrap>
                                {props.topics?.map((topic, i) => (
                                    <Category onClick={(e) => navigateToTopic(topic.id, e)} key={topic.id}>
                                        {lastInArray(topic.name, i)}
                                    </Category>
                                ))}
                            </CategoriesWrap>
                        )}
                        <Date>{props.postDate}</Date>
                        <Header>{props.name}</Header>
                        <Text>
                            {props.contentTeaser.length > 250
                                ? `${props.contentTeaser.substring(0, 250)}...`
                                : props.contentTeaser}
                        </Text>
                        <ButtonWrap>
                            <Button to={url()}>
                                <TextWrap className="active">{props.mediaData ? t('read_more') : t('read')}</TextWrap>
                                <EastIcon fontSize="small" />
                            </Button>
                        </ButtonWrap>
                    </InfoWrap>
                </Wrap>
            </CardWrap>
        </CardContainer>
    );
};
