export const theme = {
    colors: {
        black: '#212121',
        secondaryBlack: '#616161',
        outlinesAndPlaceholders: '#7E7E7E',
        outlineAndPlaceholder: '#7F7F7F',
        outlineGrey: '#E0E0E0',
        greyBg: '#F5F5F5',
        white: '#FFFFFF',
        darkGreen: '#11302D',
        middleGreen: '#1B4942',
        green: '#54B77A',
        lightGreen: '#A3D2B5',
        shadow: 'rgba(33, 33, 33, 0.1)',
        error: '#FF6446',
        yellow: '#F3BE30',
        greyGreen: '#5B7D7E',
        red: '#DD0014',
    },
    screen: {
        desktop: false,
        tablet: false,
        mobile: false,
    },
};
