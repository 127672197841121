import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { IState } from '../../../../../store';
import { Topic } from '../../../../../types/api';

const TopicsContainer = styled.div`
    ${(props) => css`
        margin-top: 8px;
        background-color: ${props.theme.colors.white};
        border-radius: 0 0 8px 8px;
        padding: 16px 16px 16px 0;
        display: flex;
        align-items: baseline;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            align-items: flex-start;
        `}
    `}
`;
const HeaderWrap = styled.div``;
const Header = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        width: 90px;
        margin-right: 24px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 13px;
            line-height: 18px;
        `}
    `}
`;
const TopicsWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    gap: 5px 0px;
`;
const Topics = styled.div`
    ${(props) => css`
        margin-right: 24px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: ${props.theme.colors.green};
        ${props.theme.screen.mobile &&
        css`
            font-size: 11px;
            line-height: 13px;
        `}
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    `}
`;

type Props = {
    topics: Topic[];
};

export const RelatedTopics = (props: Props) => {
    const navigate = useNavigate();
    const { categoriesList } = useSelector((state: IState) => state.company);

    const navigateToTopic = (topicId: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const category = categoriesList.find((category) => category.topics.find((topic) => topic.id === topicId));
        if (category) {
            navigate(`/imoniu-sarasas/${category.id}/${topicId}`);
        }
    };

    return (
        <TopicsContainer>
            <HeaderWrap>
                <Header>{t('topics')}:</Header>
            </HeaderWrap>
            {props.topics.length > 0 && (
                <TopicsWrap>
                    {props.topics.map((topic: Topic, i) => {
                        return (
                            <Topics onClick={(e) => navigateToTopic(topic.id, e)} key={i}>
                                {topic.name}
                            </Topics>
                        );
                    })}
                </TopicsWrap>
            )}
        </TopicsContainer>
    );
};
