import styled, { css } from 'styled-components';
import { t } from 'i18next';

import { TrademarkImage } from '../../../../../types/api';

const BrandsContainer = styled.div`
    ${(props) => css`
        width: 100%;
        margin-top: 16px;
        padding: 16px;
        border-radius: 8px;
        background-color: ${props.theme.colors.white};
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 17px;
        `}
    `}
`;
const LogoContainer = styled.div`
    ${(props) => css`
        margin-top: 24px;
        ${props.theme.screen.mobile &&
        css`
            margin-top: 16px;
        `}
    `}
`;
const Img = styled.img`
    ${(props) => css`
        margin-right: 32px;
        max-width: 96px;
        max-height: 35px;
        filter: grayscale(1);
        ${props.theme.screen.mobile &&
        css`
            max-width: 54px;
            max-height: 20px;
        `}
    `}
`;
const Text = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 12px;
            line-height: 14px;
        `}
    `}
`;

type Logos = {
    trademarkImages: TrademarkImage[];
};

export const BrandsRepresented = (props: Logos) => {
    return (
        <>
            {props.trademarkImages.length > 0 && (
                <BrandsContainer>
                    <Header>{t('brands_represented')}</Header>
                    <LogoContainer>
                        {props.trademarkImages.map((logo, index) => (
                            <Img key={index} src={logo.logoPath} />
                        ))}
                    </LogoContainer>
                </BrandsContainer>
            )}
        </>
    );
};
