import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MUIPagination, { PaginationRenderItemParams } from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PaginationItem } from '@mui/material';

import { getSearchCompaniesList } from '../../services/company.service';
import { resetPagination } from '../../store/company/company.slice';

import { IState } from '../../store';
import { CompanyListItem } from '../../types/api';

import eastIcon from '@mui/icons-material/East';
import styled, { css } from 'styled-components';

interface Props {
    setCompanies: (companies: CompanyListItem[]) => void;
}

const StyledStack = styled(Stack)`
    ${(props) => css`
        .pagination-item {
            background-color: ${props.theme.colors.white} !important;
            margin: 0;
            padding: 0;
            &.selected {
                font-weight: 700;
            }
        }
    `}
`;

const ArrowPrevious = styled(eastIcon)`
    transform: rotate(180deg);
`;

export const Pagination = (props: Props) => {
    const dispatch = useDispatch();
    const { pagination } = useSelector((state: IState) => state.company);
    const pageCount = Math.ceil(pagination.total / pagination.perPage);

    const handlePageChange = (event: React.ChangeEvent<unknown> | null, value: number) => {
        getSearchCompaniesList(value, pagination.query).then((res) => {
            window.scrollTo(0, 0);
            props.setCompanies(res.data);
        });
    };

    useEffect(() => {
        return () => {
            dispatch(resetPagination());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledStack sx={{ alignItems: 'center' }}>
            <MUIPagination
                siblingCount={0}
                boundaryCount={1}
                count={pageCount}
                page={pagination.currentPage}
                shape="rounded"
                variant="text"
                onChange={handlePageChange}
                renderItem={(item: PaginationRenderItemParams) => (
                    <PaginationItem
                        {...item}
                        classes={{ root: 'pagination-item', selected: 'selected' }}
                        slots={{ previous: ArrowPrevious, next: eastIcon }}
                    />
                )}
            />
        </StyledStack>
    );
};
