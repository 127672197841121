import styled, { css } from 'styled-components';
// We dont know it the logo is goingt to come or just a boolean, so I left this, because there is a possibility this will be needed.
// import certificate from '../../../../assets/images/BusinessListCard/certificate.svg';
import { Stars } from '../../../Stars/Stars';
import { CompanyStatus } from './CompanyStatus';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../../store';
import { useTranslation } from 'react-i18next';
import { CompanyData, SocialNetwork } from '../../../../types/api';

import editIcon from '@mui/icons-material/Edit';
import eastIcon from '@mui/icons-material/East';
import PinIcon from '@mui/icons-material/FmdGood';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';
import PersonIcon from '@mui/icons-material/Person';
import lext from '../../../../assets/images/BusinessListCard/lext.png';
import { ReactComponent as BoxIcon } from '../../../../assets/images/BusinessListCard/box_icon.svg';
import { ReactComponent as Facebook } from '../../../../assets/images/InnerCompanyIcons/facebook_icon.svg';
import { ReactComponent as Linkedin } from '../../../../assets/images/InnerCompanyIcons/linkedIn_icon.svg';
import { ReactComponent as Google } from '../../../../assets/images/InnerCompanyIcons/social_icon.svg';
import { ReactComponent as Twitter } from '../../../../assets/images/InnerCompanyIcons/twitter_icon.svg';
import { ReactComponent as Instagram } from '../../../../assets/images/InnerCompanyIcons/instagram_icon.svg';
import InfoIcon from '@mui/icons-material/Info';
import useOnScreen from '../../../hooks/IsElementOnScreen';
import GroupsIcon from '@mui/icons-material/Groups';
import VillaIcon from '@mui/icons-material/Villa';
import ChatIcon from '@mui/icons-material/Chat';
import { useRef } from 'react';
import classNames from 'classnames';

const HeadContainer = styled.div`
    ${(props) => css`
        margin-top: 24px;
        display: flex;
        justify-content: center;
        margin: 24px 16px 0;
        ${props.theme.screen.mobile &&
        css`
            display: block;
        `}
    `}
`;
const HeadWrap = styled.div`
    ${(props) => css`
        ${props.theme.screen.desktop &&
        css`
            width: 1254px;
        `}
        ${props.theme.screen.tablet &&
        css`
            width: 100%;
        `}
    `}
`;
const HeaderRow = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${props.theme.screen.mobile &&
        css`
            align-items: flex-start;
            position: relative;
        `}
    `}
`;
const Icons = styled.div`
    display: flex;
    align-items: center;
`;
const LogoHeader = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            align-items: flex-start;
        `}
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 20px;
            line-height: 24px;
            margin-top: 16px;
        `}
    `}
`;
const EditIcon = styled(editIcon)`
    ${(props) => css`
        color: ${props.theme.colors.darkGrey};
    `}
`;

const Logo = styled.img`
    ${(props) => css`
        object-fit: contain;
        height: 48px;
        max-width: 173px;
        margin-right: 24px;
        ${props.theme.screen.mobile &&
        css`
            height: 36px;
            max-width: 130px;
        `}
    `}
`;
const WriteReview = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        border: 1px solid ${props.theme.colors.darkGreen};
        border-radius: 8px;
        padding: 15px 24px;
        width: calc(193px - 48px);
        display: flex;
        cursor: pointer;
        margin: 16px 0 48px 0;
        &:hover {
            border-color: ${props.theme.colors.green};
            color: ${props.theme.colors.green};
        }
        ${props.theme.screen.mobile &&
        css`
            margin: 20px 0 0 0;
        `}
    `}
`;
const StarsContainer = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        ${props.theme.screen.mobile &&
        css`
            align-items: flex-start;
            margin-top: 20px;
            order: 3;
        `}
    `}
`;
const ContactsContainer = styled.div`
    ${(props) => css`
        display: flex;
        box-shadow: 0px 4px 15px 0px ${props.theme.colors.shadow};
        border-radius: 8px;
        padding: 24px 32px;
        margin-bottom: 24px;
        ${props.theme.screen.mobile &&
        css`
            border-left: none;
            border-right: none;
            width: calc(100% - 32px);
            order: 3;
            margin: 33px 0 32px 0;
            padding: 16px;
        `}
    `}
`;
const Wrap = styled.div`
    ${(props) => css`
        display: flex;
        margin-bottom: 6px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: ${props.theme.colors.secondaryBlack};
        ${props.theme.screen.mobile &&
        css`
            margin: 0;
        `};
    `}
`;
const Name = styled.div`
    margin-left: 8px;
`;
const Info = styled.div`
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    overflow-wrap: anywhere;
`;
const Contacts = styled.div`
    ${(props) => css`
        width: 100%;
        margin: 8px;
        ${props.theme.screen.mobile &&
        css`
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            height: 36px;
            width: 36px;
            margin: 0;
            background-color: ${props.theme.colors.greyBg};
        `}
    `}
`;
const ContactsWrap = styled.div`
    ${(props) => css`
        display: flex;
        width: 100%;
        ${props.theme.screen.mobile &&
        css`
            justify-content: space-between;
        `}
        &.info-header {
            width: 1254px;
            justify-content: center;
            justify-content: space-between;
        }
    `}
`;
const GreyBackgroundWhenVisible = styled.div`
    ${(props) => css`
        width: 100%;
        &.info-header {
            display: flex;
            justify-content: center;
            position: fixed;
            top: 97px;
            background-color: ${props.theme.colors.outlineGrey};
            left: 0;
            z-index: 11;
            padding: 16px;
            width: calc(100% - 32px);
            ${!props.theme.screen.desktop &&
            css`
                top: 73px;
            `}
            ${props.theme.screen.mobile &&
            css`
                padding: 8px 16px;
            `}
        }
    `}
`;
const CertificateContainer = styled.div`
    display: flex;
    align-items: center;
`;
const Certificate = styled.img`
    ${(props) => css`
        width: 40px;
        ${props.theme.screen.mobile &&
        css`
            width: 36px;
        `}
    `}
`;
const Anchor = styled.a`
    ${(props) => css`
        text-decoration: none;
        height: 100%;
        color: ${props.theme.colors.darkGreen};
        cursor: pointer;
        .icon {
            color: ${props.theme.colors.green};
            ${props.theme.screen.mobile &&
            css`
                color: ${props.theme.colors.darkGreen};
            `}
        }
    `}
`;
const EditButton = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        border: 1px solid ${props.theme.colors.darkGreen};
        border-radius: 8px;
        padding: 8px;
        gap: 6px;
        &:hover {
            border-color: ${props.theme.colors.green};
            color: ${props.theme.colors.green};
        }
    `}
`;
const Text = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
`;
const EastIcon = styled(eastIcon)`
    width: 18px !important;
    margin-left: 8px;
`;
const LextIcon = styled.img`
    ${(props) => css`
        width: 42px;
        height: 13px;
        margin-right: 40px;
        padding: 8px 21px;
        border: 1px solid ${props.theme.colors.outlineGrey};
        border-radius: 8px;
        margin-left: 24px;
    `}
`;
const StarsAndLext = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            align-items: flex-start;
            width: calc(100% - 16px);
        `}
    `}
`;
const NavigationButtons = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        display: flex;
        margin-bottom: 17px;
        white-space: nowrap;
        ${!props.theme.screen.desktop &&
        css`
            overflow-x: scroll;
        `}
    `}
`;
const Button = styled.a`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.darkGreen};
        color: ${props.theme.colors.darkGreen};
        padding: 10px;
        border-radius: 8px;
        margin-right: 16px;
        display: flex;
        text-decoration: none;
        align-items: center;
        gap: 6px;
        &:hover {
            color: ${props.theme.colors.white};
            background-color: ${props.theme.colors.darkGreen};
            .icon {
                fill: ${props.theme.colors.white};
            }
        }
        .icon {
            height: 18px;
            fill: ${props.theme.colors.darkGreen};
        }
    `}
`;

const StarsWrap = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            align-items: flex-start;
            margin-top: 20px;
            order: 3;
        `}
    `}
`;

const SocialIconsWrap = styled.div`
    ${(props) => css`
        display: flex;
        gap: 24px;
        ${props.theme.screen.mobile &&
        css`
            order: 1;
        `}
    `}
`;

const SocialIcon = styled.a`
    .social-icon {
        height: 24px !important;
        width: 24px !important;
    }
`;

interface Props {
    data: CompanyData;
    articlesLength: number;
}

export const Head = (props: Props) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);
    const dispatch = useDispatch();
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const editPage = process.env.REACT_APP_EDIT_REDIRECT
        ? `${process.env.REACT_APP_EDIT_REDIRECT}/${props.data.slug}`
        : `https://klientams.visalietuva.lt/web/login?redirect=/setcompany/${props.data.slug}`;

    const webLinkFormater = (website: string) => {
        if (!website) {
            return '';
        }
        const array = website.split('/');
        return array[2];
    };

    const addNumberSpacing = (phone: string) => {
        const firstThreeNumbers = phone.substring(0, 4);
        if (firstThreeNumbers === '+370') {
            const validNumber = phone.replace(/(\d{3})(\d{3})(\d{5})/, '$1 $2 $3');
            return validNumber;
        }
        return phone;
    };

    const showInMapClicked = (lat: number, long: number) => {
        if (lat && long) {
            window.open(`https://maps.google.com?q=${lat},${long}`);
        }
    };

    const socialIconsFilter = () => {
        const socials: { [key: string]: JSX.Element } = {
            facebook: <Facebook className="social-icon" />,
            linkedin: <Linkedin className="social-icon" />,
            google: <Google className="social-icon" />,
            twitter: <Twitter className="social-icon" />,
            instagram: <Instagram className="social-icon" />,
        };
        const filterIcons = props.data.socialNetworks.map((network: SocialNetwork, id) => {
            const socialName = network.socialNetwork;
            return (
                <SocialIcon href={network.url} target="_blank" key={id}>
                    {socials[socialName]}
                </SocialIcon>
            );
        });
        return filterIcons;
    };

    const zipFormatter = () => {
        const zipNumbers = props.data.address.zip.replace(/\D/g, '');
        return zipNumbers;
    };

    const information = [
        {
            name: t('address'),
            information: (
                <Anchor
                    onClick={() => {
                        showInMapClicked(props.data.address.latitude, props.data.address.longitude);
                    }}
                >
                    {`${props.data.address.street ? `${props.data.address.street},` : ''} ${
                        props.data.address.buildingNum
                    }${props.data.address.roomNum ? `-${props.data.address.roomNum}` : ''}${
                        props.data.address.buildingNum.trim() ? ',' : ''
                    } ${props.data.address.zip ? `LT-${zipFormatter()},` : ''} ${props.data.address.city}`}
                </Anchor>
            ),
            icons: (
                <Anchor
                    onClick={() => {
                        showInMapClicked(props.data.address.latitude, props.data.address.longitude);
                    }}
                >
                    <PinIcon fontSize="small" className="icon" />
                </Anchor>
            ),
        },
        {
            name: t('phone'),
            information: (
                <Anchor onClick={(e) => e.stopPropagation()} href={`tel:${props.data.phone}`}>
                    {addNumberSpacing(props.data.phone)}
                </Anchor>
            ),
            icons: (
                <Anchor onClick={(e) => e.stopPropagation()} href={`tel:${props.data.phone}`}>
                    <LocalPhoneIcon fontSize="small" className="icon" />
                </Anchor>
            ),
        },
        {
            name: t('email'),
            information: (
                <Anchor onClick={(e) => e.stopPropagation()} href={`mailto:${props.data.email}`}>
                    {props.data.email}
                </Anchor>
            ),
            icons: (
                <Anchor onClick={(e) => e.stopPropagation()} href={`mailto:${props.data.email}`}>
                    <EmailIcon fontSize="small" className="icon" />
                </Anchor>
            ),
        },
        {
            name: t('website'),
            information: (
                <Anchor onClick={(e) => e.stopPropagation()} href={props.data.website} target="_blank">
                    {webLinkFormater(props.data.website)}
                </Anchor>
            ),
            icons: (
                <Anchor onClick={(e) => e.stopPropagation()} href={props.data.website}>
                    <LanguageIcon fontSize="small" className="icon" />
                </Anchor>
            ),
        },
        {
            name: t('manager'),
            information: props.data.manager?.name,
            icons: (
                <Anchor onClick={(e) => e.stopPropagation()} href={props.data.website}>
                    <PersonIcon fontSize="small" className="icon" />
                </Anchor>
            ),
            hideMobile: true,
        },
    ];

    const goToreviews = () => {
        window.open(`${process.env.REACT_APP_IMONES_URL}/imones-kortele/${props.data.id}`, '_blank');
    };

    return (
        <HeadContainer>
            <HeadWrap>
                <HeaderRow>
                    <LogoHeader>
                        {props.data.logo !== null && <Logo src={props.data.logo.logoPath} />}
                        <Header>{props.data.name}</Header>
                    </LogoHeader>
                    <Icons>
                        <Anchor href={editPage} target="_blank">
                            <EditButton>
                                <EditIcon fontSize="small" />
                                <Text>{t('edit')}</Text>
                            </EditButton>
                        </Anchor>
                    </Icons>
                </HeaderRow>
                {/* At the momment the status will show up only when the legal status is not an empty string. Odoo only send '' atm. */}
                {props.data.legalStatus && screenViewPort !== 'mobile' && (
                    <CompanyStatus status={props.data.legalStatus} />
                )}
                <StarsContainer>
                    <StarsAndLext>
                        <StarsWrap>
                            <Stars
                                rating={Number(props.data.rating)}
                                feedbackCount={props.data.feedbackCount}
                                starClass={'bigger-stars'}
                            />
                            {props.data.lextIntegration && <LextIcon src={lext} />}
                        </StarsWrap>
                        {props.data.legalStatus && screenViewPort === 'mobile' && (
                            <CompanyStatus status={props.data.legalStatus} />
                        )}
                        {socialIconsFilter().length > 0 && <SocialIconsWrap>{socialIconsFilter()}</SocialIconsWrap>}
                    </StarsAndLext>
                    <WriteReview onClick={() => goToreviews()}>
                        {t('write_a_review')}
                        <EastIcon fontSize="small" />
                    </WriteReview>
                </StarsContainer>
                <ContactsContainer ref={ref}>
                    <GreyBackgroundWhenVisible className={classNames({ 'info-header': !isVisible })}>
                        <ContactsWrap className={classNames({ 'info-header': !isVisible })}>
                            <>
                                {information.map(
                                    (data, index, row) =>
                                        (screenViewPort !== 'mobile' ||
                                            (screenViewPort === 'mobile' && !data.hideMobile)) && (
                                            <Contacts key={index}>
                                                <Wrap>
                                                    {data.icons}
                                                    {screenViewPort !== 'mobile' && <Name>{data.name}</Name>}
                                                </Wrap>
                                                {screenViewPort !== 'mobile' && <Info>{data.information}</Info>}
                                            </Contacts>
                                        )
                                )}
                                {props.data.certificates.length > 0 && (
                                    <CertificateContainer>
                                        {props.data.certificates.map((certificate, index) => {
                                            return (
                                                certificate.image &&
                                                index === 0 && <Certificate key={index} src={certificate.image} />
                                            );
                                        })}
                                    </CertificateContainer>
                                )}
                            </>
                        </ContactsWrap>
                    </GreyBackgroundWhenVisible>
                </ContactsContainer>
                <NavigationButtons>
                    <Button href="#information">
                        <InfoIcon className="icon" />
                        {t('information')}
                    </Button>
                    {props.data.products.length > 0 && (
                        <Button href="#products">
                            <BoxIcon className="icon" />
                            {t('products')}
                        </Button>
                    )}
                    {/* Not implemented */}
                    {/* <Button>
                        <InsertDriveFileIcon className="icon" />
                        {t('reports')}
                    </Button> */}
                    {props.data.workerStatistics.totalCount.length > 0 && (
                        <Button href="#employees">
                            <GroupsIcon className="icon" />
                            {t('employees')}
                        </Button>
                    )}
                    {props.data.childCompanies.length > 0 && (
                        <Button href="#branches">
                            <VillaIcon className="icon" />
                            {t('branches')}
                        </Button>
                    )}
                    {props.articlesLength > 0 && (
                        <Button href="#articles">
                            <ChatIcon className="icon" />
                            {t('articles_and_announcements')}
                        </Button>
                    )}
                </NavigationButtons>
            </HeadWrap>
        </HeadContainer>
    );
};
