import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { CompanyResponse } from '../../../../../types/api';
import { Stars } from '../../../../Stars/Stars';
import eastIcon from '@mui/icons-material/East';
import { TemporaryReviewModal } from './TemporaryReviewModal';

const ReviewsContainer = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.white};
        padding: 16px;
        border-radius: 8px;
        ${!props.theme.screen.mobile &&
        css`
            border-top: 1px solid ${props.theme.colors.outlineGrey};
            border-radius: 0 0 8px 8px;
        `}
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 16px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 17px;
        `}
    `}
`;
const Text = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 18px;
        color: ${props.theme.colors.secondaryBlack};
    `}
`;
const Review = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        margin-top: 18px;
        color: ${props.theme.colors.green};
        width: fit-content;
        display: flex;
        align-items: center;
        cursor: pointer;
        ${props.theme.screen.mobile &&
        css`
            font-size: 12px;
            line-height: 14px;
        `}
    `}
`;
const Button = styled.div`
    &:hover {
        font-weight: 600;
        &:after {
            transform: translateY(4px);
            margin-top: -1px;
            content: '';
            height: 2px;
            display: flex;
            width: 52%;
            margin-left: 24%;
            align-items: center;
            justify-content: center;
            background-color: ${(props) => props.theme.colors.green};
        }
    }
`;
const Arrow = styled(eastIcon)`
    width: 18px !important;
    margin-left: 8px;
`;

export const Reviews = (props: CompanyResponse) => {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <>
            <ReviewsContainer>
                <Header>{t('reviews')}</Header>
                <Text>{t('overall_rating_of_the_company')}</Text>
                <Stars rating={props.data.rating} feedbackCount={props.data.feedbackCount} />
                <Review>
                    <Button onClick={() => setModalOpen(true)}>{t('read_reviews')}</Button>
                    <Arrow />
                </Review>
            </ReviewsContainer>
            {modalOpen && (
                <TemporaryReviewModal isModalOpen={modalOpen} onClose={setModalOpen} companyId={props.data.id} />
            )}
        </>
    );
};
