import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/images/Header/visa_lietuva_logo.svg';
import styled, { css } from 'styled-components';
import { BurgerMenu } from './BurgerMenu';
import { IState } from '../../store/index';
import { ROUTE_NAMES } from '../../constants/routes';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';
import personIcon from '@mui/icons-material/Person';
import searchIcon from '@mui/icons-material/Search';
import { setIsSearchOpen, setLoginModalOpen } from '../../store/global.slice';
import { UserProfile } from './UserProfile';
import { LoginModal } from '../LoginModal/LoginModal';

const HeaderCenter = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        border-bottom: 1px solid ${props.theme.colors.darkGreen};
        ${!props.theme.screen.desktop &&
        css`
            justify-content: flex-start;
        `}
    `}
`;
const HeaderWrapper = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: space-between;
        color: ${props.theme.colors.black};
        height: 96px;
        display: flex;
        padding: 0 16px;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        ${props.theme.screen.desktop &&
        css`
            width: 1254px;
        `}
        ${!props.theme.screen.desktop &&
        css`
            height: 72px;
        `}
    `}
`;
const ItemWrapper = styled.div`
    display: flex;
    width: 62%;
    align-items: center;
    justify-content: space-between;
    color: ${(props) => props.theme.colors.white};
    &.language-switcher {
        width: 40px;
        height: 100%;
    }
`;
const Buttons = styled.div`
    display: flex;
    align-items: center;
`;
const HeaderLogo = styled(Logo)`
    ${(props) => css`
        @media screen and (max-width: 1300px) {
            height: 24px;
            width: 170px;
        }
        ${!props.theme.screen.desktop &&
        css`
            height: 22px;
            width: 161px;
        `}
    `}
`;
const Divider = styled.div`
    height: 24px;
    width: 1px;
    margin: 0 16px;
    background-color: ${(props) => props.theme.colors.darkGreen};
`;
const Wrap = styled.div`
    display: flex;
    align-items: center;
`;
const StyledLink = styled(NavLink)`
    text-decoration: none;
    color: ${(props) => props.theme.colors.white};
    &:hover,
    &.active {
        color: ${(props) => props.theme.colors.green};
        font-weight: 600;
        transform: translateY(1px);
        &:after {
            content: '';
            height: 2px;
            display: flex;
            width: 52%;
            margin-left: 24%;
            position: relative;
            align-items: center;
            justify-content: center;
            top: 4px;
            background-color: ${(props) => props.theme.colors.green};
        }
    }
`;
const FullHeader = styled.div`
    position: fixed;
    width: 100%;
    background-color: ${(props) => props.theme.colors.darkGreen};
    z-index: 1301;
`;
const LoginButton = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: ${props.theme.colors.white};
        cursor: pointer;
        display: flex;
        align-items: center;
        border: 1px solid ${props.theme.colors.white};
        border-radius: 8px;
        padding: 14px 24px;
        margin-right: 16px;
        &:hover {
            background-color: ${props.theme.colors.green};
            color: ${props.theme.colors.darkGreen};
            border-color: ${props.theme.colors.green};
        }
    `}
`;
const RegisterButton = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: ${props.theme.colors.darkGreen};
        cursor: pointer;
        padding: 15px 24px;
        background-color: ${props.theme.colors.green};
        border-radius: 8px;
        margin-right: 16px;
        &:hover {
            background-color: ${props.theme.colors.lightGreen};
        }
    `}
`;

const PersonIcon = styled(personIcon)`
    width: 18px !important;
    height: 18px !important;
    margin-right: 8px;
`;

const SearchIcon = styled(searchIcon)`
    cursor: pointer;
    padding-right: 30px;
    color: ${(props) => props.theme.colors.white};
    padding-left: 8px;
    &.mobile {
        position: absolute;
        right: 32px;
        top: 35px;
        transform: translateY(-50%);
    }
`;

const loginUrl = process.env.REACT_APP_LOGIN_REDIRECT || 'http://kl67.l.dedikuoti.lt:8071/web/login';

export const Header = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { screenViewPort, isSearchOpen, loginModalOpen } = useSelector((state: IState) => state.global);
    const { me } = useSelector((state: IState) => state.user);

    const [userAction, setUserAction] = useState('');

    const isLandingPage = location.pathname === ROUTE_NAMES.HOME;

    const handleSearchBarState = () => {
        dispatch(setIsSearchOpen(!isSearchOpen));
    };

    const handleAction = (action: string) => {
        setUserAction(action);
        dispatch(setLoginModalOpen(true));
    };

    return (
        <FullHeader>
            <HeaderCenter>
                <HeaderWrapper>
                    <ItemWrapper>
                        <Link to={ROUTE_NAMES.HOME}>
                            <HeaderLogo />
                        </Link>
                        {screenViewPort === 'desktop' && (
                            <Buttons className="header-buttons">
                                <StyledLink to={ROUTE_NAMES.ALL_CATEGORIES}>{t('companies_catalogue')}</StyledLink>
                                <Divider />
                                <StyledLink to={ROUTE_NAMES.ARTICLES_PAGE}>{t('articles')}</StyledLink>
                                <Divider />
                                <StyledLink to={ROUTE_NAMES.MEDIA_PAGE}>{t('press_releases')}</StyledLink>
                            </Buttons>
                        )}
                    </ItemWrapper>
                    {screenViewPort === 'desktop' && (
                        <Wrap>
                            {!isLandingPage && (
                                <ItemWrapper>
                                    <SearchIcon onClick={() => handleSearchBarState()} />
                                </ItemWrapper>
                            )}
                            {!me ? (
                                <>
                                    <ItemWrapper>
                                        <LoginButton onClick={() => handleAction('login')}>
                                            <PersonIcon />
                                            {t('login')}
                                        </LoginButton>
                                    </ItemWrapper>
                                    <ItemWrapper>
                                        <RegisterButton onClick={() => handleAction('register')}>
                                            {t('register')}
                                        </RegisterButton>
                                    </ItemWrapper>
                                </>
                            ) : (
                                <UserProfile />
                            )}
                            <ItemWrapper className="language-switcher">
                                <LanguageSwitcher />
                            </ItemWrapper>
                            {loginModalOpen && (
                                <LoginModal isLoginOpen={loginModalOpen} selectedAction={userAction || 'login'} />
                            )}
                        </Wrap>
                    )}
                    {screenViewPort !== 'desktop' && !isLandingPage && (
                        <SearchIcon className="mobile" onClick={() => handleSearchBarState()} />
                    )}
                    {screenViewPort !== 'desktop' && <BurgerMenu />}
                </HeaderWrapper>
            </HeaderCenter>
        </FullHeader>
    );
};
