import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { IState } from '../../store';
import { getMediaPost } from '../../services/media.service';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import VillaIcon from '@mui/icons-material/Villa';
import { Media } from '../../types/api';
import { AdCard } from '../AdCard/AdCard';
import { Gallery } from '../Gallery/Gallery';
import classNames from 'classnames';
import { setBreadcrumb } from '../../store/global.slice';

const ArticlesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;
const ArticleContainerWrap = styled.div`
    width: 1254px;
    margin: 0 16px;
`;
const ArticleInnerContainer = styled.div`
    display: flex;
`;
const ArticleWrap = styled.div`
    width: 100%;
    height: fit-content;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            display: flex;
            flex-wrap: wrap;
        `}
`;
const HeaderContainer = styled.div`
    align-items: center;
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 32px;
        font-weight: 500;
        line-height: 38px;
        margin-top: 8px;
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 16px;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
        `}
    `}
`;
const Date = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.005em;
    margin: 24px 0;
    width: 100%;
`;
const HtmlContent = styled.div`
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 8px;
        `}
    `}
`;
const InformationContainer = styled.div`
    ${(props) =>
        css`
            box-shadow: 0px 4px 15px 0px ${props.theme.colors.shadow};
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            height: 63px;
            padding: 16px 32px;
            border-radius: 8px;
            &.no-logo {
                justify-content: normal;
            }
            ${!props.theme.screen.desktop &&
            css`
                flex-direction: column;
                height: auto;
                align-items: start;
                padding: 16px;
            `}
        `}
`;
const LogoContainer = styled.div``;
const CompanyContainer = styled.div`
    width: max-content;
`;
const EmailPhoneContainer = styled.div`
    ${(props) =>
        css`
            display: flex;
            height: 100%;
            align-items: center;
            ${!props.theme.screen.desktop &&
            css`
                margin-top: 16px;
                gap: 32px;
            `}
        `}
`;
const Wrap = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: ${props.theme.colors.secondaryBlack};
        display: flex;
        align-items: center;
        .icon {
            width: 18px !important;
            color: ${props.theme.colors.green};
            margin-right: 8px;
        }
    `}
`;
const EmailWrap = styled.div``;
const PhoneWrap = styled.div``;
const Text = styled.a`
    ${(props) => css`
        text-decoration: none;
        color: ${props.theme.colors.black};
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        overflow-wrap: anywhere;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            line-height: 17px;
        `}
    `}
`;
const Logo = styled.img`
    ${(props) => css`
        max-width: 120px;
        height: 63px;
        ${!props.theme.screen.desktop &&
        css`
            max-width: 70px;
            height: 37px;
        `}
    `}
`;
const Divider = styled.div`
    ${(props) =>
        css`
            min-width: 1px;
            background-color: ${props.theme.colors.outlineGrey};
            margin: 0 28px;
            height: 100%;
            ${!props.theme.screen.desktop &&
            css`
                &.header {
                    width: 100%;
                    height: 1px;
                    margin: 16px 0;
                }
            `}
        `}
`;
const MainBlock = styled.div`
    ${(props) =>
        css`
            display: flex;
            width: 100%;
            gap: 30px;
            ${!props.theme.screen.desktop &&
            css`
                grid-template-columns: 100%;
            `}
        `}
`;
const LeftSideInfo = styled.div`
    width: 100%;
`;

const ExpandedContainer = styled.div`
    ${(props) => css`
        &.mobile {
            margin: 24px 0 32px 0;
        }
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
            display: flex;
            justify-content: center;
        `}
    `}
`;
const AdsWrapper = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        background: ${(props) => props.theme.colors.greyBg};
        border-radius: 8px;
        padding: 16px;
        gap: 30px;
        ${!props.theme.screen.desktop &&
        css`
            width: fit-content;
            padding: 16px 39px;
        `}
    `}
`;
const GalleryContainer = styled.div`
    ${(props) =>
        css`
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: ${props.theme.colors.greyBg};
            margin-top: 24px;
        `}
`;
const GalleryWrap = styled.div`
    width: calc(100% - 32px);
    max-width: 1254px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 16px;
    gap: auto;
`;

export const MediaInner = () => {
    const dispatch = useDispatch();
    const [mediaArticle, setMediaArticle] = useState<Media>();
    const { screenViewPort, bannersList } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();
    const urlParams = useParams();

    useEffect(() => {
        if (urlParams.cardId) {
            getMediaPost(parseInt(urlParams.cardId)).then((resp) => {
                setMediaArticle(resp);
                dispatch(
                    setBreadcrumb({
                        key: 'mediaName',
                        value: resp.name.charAt(0).toUpperCase() + resp.name.slice(1),
                    })
                );
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formattedBanners = () => {
        const list = [];
        if (bannersList.WBQ.length > 0) {
            list.push(bannersList.WBQ[0]);
        }
        if (bannersList.WBM.length > 0) {
            list.push(...bannersList.WBM.slice(0, 3));
        }
        if (bannersList.WBC.length > 0) {
            list.push(bannersList.WBC[0]);
        }
        if (bannersList.WBD.length > 0) {
            list.push(bannersList.WBD[0]);
        }
        return list;
    };

    return (
        <>
            {mediaArticle && (
                <>
                    <ArticlesContainer>
                        <ArticleContainerWrap>
                            <HeaderContainer>
                                <Header>{mediaArticle.name}</Header>
                                <Date>{mediaArticle.postDate}</Date>
                            </HeaderContainer>
                            <MainBlock>
                                <LeftSideInfo>
                                    <InformationContainer
                                        className={classNames({ 'no-logo': !mediaArticle.company.logo?.logoPath })}
                                    >
                                        {mediaArticle.company.logo && (
                                            <>
                                                <LogoContainer>
                                                    <Logo src={mediaArticle.company.logo.logoPath} />
                                                </LogoContainer>
                                                <Divider className="header" />
                                            </>
                                        )}
                                        <CompanyContainer>
                                            <Wrap>
                                                <VillaIcon className="icon" />
                                                {t('company')}
                                            </Wrap>
                                            <Text>{mediaArticle.company.name}</Text>
                                        </CompanyContainer>
                                        {screenViewPort === 'desktop' && <Divider />}
                                        <EmailPhoneContainer>
                                            <EmailWrap>
                                                <Wrap>
                                                    <EmailIcon className="icon" />
                                                    {t('email')}:
                                                </Wrap>
                                                <Text
                                                    onClick={(e) => e.stopPropagation()}
                                                    href={`mailto:${mediaArticle.company.email}`}
                                                    target="_blank"
                                                >
                                                    {mediaArticle.company.email}
                                                </Text>
                                            </EmailWrap>
                                            {screenViewPort === 'desktop' && <Divider />}
                                            <PhoneWrap>
                                                <Wrap>
                                                    <PhoneIcon className="icon" />
                                                    {t('phone')}:
                                                </Wrap>
                                                <Text
                                                    onClick={(e) => e.stopPropagation()}
                                                    href={`tel:${mediaArticle.company.email}`}
                                                    target="_blank"
                                                >
                                                    {mediaArticle.company.phone}
                                                </Text>
                                            </PhoneWrap>
                                        </EmailPhoneContainer>
                                    </InformationContainer>
                                    <ArticleInnerContainer>
                                        <ArticleWrap>
                                            <HtmlContent dangerouslySetInnerHTML={{ __html: mediaArticle.content }} />
                                        </ArticleWrap>
                                    </ArticleInnerContainer>
                                </LeftSideInfo>
                                {screenViewPort === 'desktop' && (
                                    <ExpandedContainer>
                                        <AdsWrapper>
                                            {formattedBanners().map((banner) => {
                                                return (
                                                    <AdCard
                                                        key={banner.id}
                                                        adUrl={banner.image}
                                                        adHref={banner.url}
                                                        customDimensions={
                                                            screenViewPort === 'desktop'
                                                                ? { height: 366, width: 366 }
                                                                : { height: 250, width: 250 }
                                                        }
                                                    />
                                                );
                                            })}
                                        </AdsWrapper>
                                    </ExpandedContainer>
                                )}
                            </MainBlock>
                        </ArticleContainerWrap>
                    </ArticlesContainer>
                    {mediaArticle.articleImages.length > 0 && (
                        <GalleryContainer>
                            <GalleryWrap>
                                <Gallery arrayOfImages={mediaArticle.articleImages} />
                            </GalleryWrap>
                        </GalleryContainer>
                    )}
                    {screenViewPort !== 'desktop' && (
                        <ExpandedContainer className="mobile">
                            <AdsWrapper>
                                {formattedBanners()
                                    .slice(0, 1)
                                    .map((banner) => {
                                        return (
                                            <AdCard
                                                key={banner.id}
                                                adUrl={banner.image}
                                                adHref={banner.url}
                                                customDimensions={{ height: 250, width: 250 }}
                                            />
                                        );
                                    })}
                            </AdsWrapper>
                        </ExpandedContainer>
                    )}
                </>
            )}
        </>
    );
};
