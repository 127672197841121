import { store } from '../store';
import { setPagination } from '../store/company/company.slice';
import { setLoading } from '../store/global.slice';
import {
    CompanyListResponse,
    CompanySearchFilters,
    SearchFiltersResponse,
    QueryParams,
    IFeedbackPostResponse,
    FullFeedback,
    IFeedbackGetResponse,
    CategoriesResponse,
} from '../types/api';
import http from './client/http';

export const getCompaniesList = () => http.get<CompanyListResponse>(`/companies`).then((res) => res.data.data);
export const getIndividualCompany = (id: number) => {
    store.dispatch(setLoading(true));
    return http
        .get<any>(`/companies/${id}`)
        .then((res) => {
            return res.data.data;
        })
        .finally(() => store.dispatch(setLoading(false)));
};

export const getSearchCompanies = (page = 1, filters: CompanySearchFilters) => {
    store.dispatch(setLoading(true));
    let additionalParams = '';

    Object.keys(filters).forEach((key) => {
        additionalParams += `&${key}=${filters[key as keyof typeof filters]}`;
    });

    return http
        .get<CompanyListResponse>(`/companies/search?page=${page}${additionalParams}`)
        .then((res) => {
            const pagination = {
                currentPage: res.data.data.currentPage,
                perPage: res.data.data.perPage,
                total: res.data.data.total,
                query: queryParamSplitter(res.data.data.firstPageUrl),
            };

            store.dispatch(setPagination(pagination));
            return res.data.data;
        })
        .finally(() => store.dispatch(setLoading(false)));
};

export const getSearchFilters = () => {
    return http.get<SearchFiltersResponse>('/companies/search_filters').then((res) => res.data.data);
};

export const getSearchCompaniesList = (page = 1, filters: QueryParams) => {
    store.dispatch(setLoading(true));
    let additionalParams = '';

    Object.keys(filters).forEach((key) => {
        const filter = filters[key as keyof typeof filters];
        if (filter) {
            if (Array.isArray(filter) && filter.length > 0) {
                additionalParams += `&${key}=${filter.map((item) => item.day).join()}`;
            }
            if (!Array.isArray(filter)) {
                additionalParams += `&${key}=${filter}`;
            }
        }
    });

    return http
        .get<CompanyListResponse>(`companies/search?page=${page}${additionalParams}`)
        .then((res) => {
            const pagination = {
                currentPage: res.data.data.currentPage,
                perPage: res.data.data.perPage,
                total: res.data.data.total,
                query: queryParamSplitter(res.data.data.firstPageUrl),
            };

            store.dispatch(setPagination(pagination));

            return res.data.data;
        })
        .finally(() => store.dispatch(setLoading(false)));
};

export const getCompanyFeedback = (id: number) =>
    http.get<IFeedbackGetResponse>(`/companies/${id}/feedback`).then((res) => res.data.data);

export const postCompanyFeedback = (id: number, data: FullFeedback) => {
    store.dispatch(setLoading(true));
    return http
        .post<IFeedbackPostResponse>(`/companies/${id}/feedback`, data)
        .then((res) => res.data.data)
        .finally(() => store.dispatch(setLoading(false)));
};

const queryParamSplitter = (query: string) => {
    const splitParams = query.split('&');
    const queryParams = splitParams.slice(1);
    const queryObj: any = {};
    queryParams.forEach((param) => {
        const [key, value] = param.split('=');
        queryObj[key] = decodeURIComponent(value);
    });

    return queryObj;
};

export const getCategoriesList = () =>
    http.get<CategoriesResponse>(`/categories?withTopics=true&withHeads=true`).then((res) => res.data.data);
