import { connect } from 'react-redux';
import { IState } from '../../../store';

const PureMediaNameBreadcrumb = ({ name }: { name: string }) => {
    return <span>{name}</span>;
};

const mapStateToProps = (state: IState, props: any) => {
    const mediaName = state.global.breadcrumbs.mediaName;

    return { name: mediaName || '...' };
};

export default connect(mapStateToProps)(PureMediaNameBreadcrumb);
