import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { IState } from '../../../../../store';
import { ICategory, Topic, TopicHead } from '../../../../../types/api';

const TopicsContainer = styled.div`
    ${(props) => css`
        margin-top: 8px;
        background-color: ${props.theme.colors.white};
        border-radius: 0 0 8px 8px;
        padding: 16px;
        display: flex;
        align-items: baseline;
        box-shadow: 0px 4px 15px 0px ${props.theme.colors.shadow};
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            align-items: flex-start;
        `}
    `}
`;
const HeaderWrap = styled.div``;
const Header = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        width: 100px;
        margin-right: 24px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 13px;
            line-height: 18px;
        `}
    `}
`;
const TopicsWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    gap: 5px 0px;
`;
const Topics = styled.div`
    ${(props) => css`
        margin-right: 24px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        :first-letter {
            text-transform: uppercase;
        }
        color: ${props.theme.colors.green};
        ${props.theme.screen.mobile &&
        css`
            font-size: 11px;
            line-height: 13px;
        `}
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    `}
`;

type Props = {
    heads: TopicHead[];
};

export const RelatedHeads = (props: Props) => {
    const navigate = useNavigate();
    const { categoriesList } = useSelector((state: IState) => state.company);

    const navigateToBusinessList = (headId?: number) => {
        let categoryId = '';
        const category = categoriesList.find((category: ICategory) =>
            category.topics.find((topic: Topic) => topic.heads.find((head: TopicHead) => head.id == headId))
        );

        const topic = category?.topics.find((topic: Topic) => topic.heads.find((head: TopicHead) => head.id == headId));

        categoryId = category?.id.toString() || '';

        navigate(`/imoniu-sarasas/${categoryId}/${topic?.id}${headId ? `/${headId}` : ''}`);
    };

    return (
        <TopicsContainer>
            <HeaderWrap>
                <Header>{t('area_of_activities')}:</Header>
            </HeaderWrap>
            {props.heads.length > 0 && (
                <TopicsWrap>
                    {props.heads.map((head: TopicHead, i) => {
                        return (
                            <Topics onClick={(e) => navigateToBusinessList(head.id)} key={i}>
                                {head.name}
                            </Topics>
                        );
                    })}
                </TopicsWrap>
            )}
        </TopicsContainer>
    );
};
