import styled, { css } from 'styled-components';
import { t } from 'i18next';

const BrandsContainer = styled.div`
    ${(props) => css`
        width: calc(100% - 32px);
        margin-top: 8px;
        padding: 16px;
        display: flex;
        background-color: ${props.theme.colors.white};
    `}
`;
const Header = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 24px;
`;
const Text = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
`;

const SearchWordsWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

type Props = {
    searchWords: SearchWord[];
};

type SearchWord = {
    keywords: string;
};

export const SearchWords = (props: Props) => {
    return (
        <>
            {props.searchWords && props.searchWords.length > 0 && (
                <BrandsContainer>
                    <Header>{t('search_words')}</Header>
                    <SearchWordsWrap>
                        {props.searchWords.map((word) => (
                            <Text>{word.keywords}</Text>
                        ))}
                    </SearchWordsWrap>
                </BrandsContainer>
            )}
        </>
    );
};
