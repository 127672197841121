import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { WorkingTime } from '../../../../../types/api';
import { IState } from '../../../../../store';
import { useSelector } from 'react-redux';

const WorkingHoursContainer = styled.div`
    ${(props) => css`
        padding: 16px;
        background-color: ${props.theme.colors.white};
        border-radius: 8px 0 0 8px;
        ${!props.theme.screen.mobile &&
        css`
            border-radius: 8px 8px 0 0;
        `}
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 16px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 17px;
        `}
    `}
`;
const WeekWrap = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: ${props.theme.colors.secondaryBlack};
        height: 125px;
        width: 210px;
        gap: 0 30px;
        &.english-translation {
            width: 251px;
        }
        ${props.theme.screen.mobile &&
        css`
            height: 175px;
        `}
    `}
`;
const DayWrap = styled.div`
    display: flex;
    margin-top: 8px;
`;
const Day = styled.div`
    ${(props) => css`
        font-weight: 600;
        color: ${props.theme.colors.black};
        width: 12px;
        &.english-translation {
            width: 25px;
        }
        &.weekend {
            color: ${props.theme.colors.green};
        }
    `}
`;
const Time = styled.div`
    margin-left: 8px;
`;

type Props = {
    time: WorkingTime[];
};

export const WorkingHours = (props: Props) => {
    const { t } = useTranslation();
    const { selectedLanguage } = useSelector((state: IState) => state.language);
    const weekDay = [t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday')];

    const formatTime = (time: string) => {
        if (!time) {
            return t('not_working');
        }
        const date = time.split(':');
        const hours = +date[0];
        const minutes = date[1];
        const formatedTime = `${hours}.${minutes}`;
        return formatedTime;
    };

    return (
        <WorkingHoursContainer>
            <Header>{t('working_hours')}</Header>
            <WeekWrap
                className={classNames({
                    'english-translation': selectedLanguage === 'en',
                })}
            >
                {props.time.length > 0 ? (
                    props.time.map((day, i) => {
                        return (
                            <DayWrap key={i}>
                                <Day
                                    className={classNames({
                                        weekend: i > 4,
                                        'english-translation': selectedLanguage === 'en',
                                    })}
                                >
                                    {weekDay[i]}
                                </Day>
                                <Time>
                                    {!day.openTime
                                        ? t('not_working')
                                        : `${formatTime(day.openTime)} - ${formatTime(day.closeTime)}`}
                                </Time>
                            </DayWrap>
                        );
                    })
                ) : (
                    <div>{`${t('not_provided')}`}</div>
                )}
            </WeekWrap>
        </WorkingHoursContainer>
    );
};
