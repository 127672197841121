import classNames from 'classnames';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IState } from '../../store';
import { useLocation, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

const BannerContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 32px;
    &.gate-styles {
        padding-top: 170px;
        margin-top: -100vh;
    }
`;
const BannerWrap = styled.div`
    max-width: 1254px;
    margin: 0 16px 32px 16px;
    min-height: 116px;
    max-height: 116px;
    width: 100%;
`;
const Banner = styled.img`
    object-fit: fill;
    height: 100%;
    border-radius: 10px;
    width: 100%;
`;

type Props = {
    imgUrl?: string;
    imgHref?: string;
    isGateAllowed: boolean;
};

export const HorizontalBanner = (props: Props) => {
    const { bannersList, bannersFilteredByTopic, bannersFilteredByKeyword } = useSelector(
        (state: IState) => state.global
    );
    const [banner, setBanner] = useState({
        imgUrl: props.imgUrl || '',
        imgHref: props.imgHref || '',
    });

    const location = useLocation();
    const urlParams = useParams();

    useEffect(() => {
        if (urlParams?.topicId && bannersFilteredByTopic.WBR.length > 0) {
            setBanner({
                imgUrl: bannersFilteredByTopic.WBR[0].image,
                imgHref: bannersFilteredByTopic.WBR[0].url,
            });
            return;
        }

        if (bannersFilteredByKeyword.WBZ.length > 0) {
            setBanner({
                imgUrl: bannersFilteredByKeyword.WBZ[0].image,
                imgHref: bannersFilteredByKeyword.WBZ[0].url,
            });

            return;
        }

        if (bannersList.WBP.length > 0) {
            setBanner({
                imgUrl: bannersList.WBP[0].image,
                imgHref: bannersList.WBP[0].url,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersList.WBP, bannersFilteredByTopic, bannersFilteredByKeyword]);

    return (
        <>
            <BannerContainer
                className={classNames({
                    'gate-styles': props.isGateAllowed,
                })}
            >
                {banner.imgUrl && (
                    <BannerWrap>
                        <a href={banner.imgHref} target="_blank" rel="noreferrer">
                            <Banner src={banner.imgUrl} />
                        </a>
                    </BannerWrap>
                )}
            </BannerContainer>
        </>
    );
};
