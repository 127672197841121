import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { IState } from '../../../../../store';
import { Product } from '../../../../../types/api';
import React from 'react';
import classNames from 'classnames';

const Container = styled.div`
    width: 100%;
`;

const ProductsContainer = styled.div`
    ${(props) => css`
        margin-top: 24px;
        width: 100%;
        background-color: ${props.theme.colors.white};
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 48px;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            margin-bottom: 32px;
        `}
    `}
`;
const ProductWrap = styled.div`
    display: flex;
    width: calc(50% - 32px);
    margin: 16px;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            width: calc(100% - 32px);
        `}
    `}
`;
const Img = styled.img`
    ${(props) => css`
        height: 145px;
        width: 139px;
        margin-right: 16px;
        object-fit: cover;
        ${props.theme.screen.mobile &&
        css`
            height: 139px;
            width: 117px;
        `}
    `}
`;
const Wrap = styled.div``;
const Text = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        &.name {
            margin-bottom: 8px;
            font-weight: 600;
        }
        &.no-information {
            padding: 24px 16px 16px;
        }
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 17px;
        `}
    `}
`;
const Divider = styled.div`
    ${(props) => css`
        width: 100%;
        height: 1px;
        background-color: ${props.theme.colors.outlineGrey};
    `}
`;
const Header = styled.div`
    ${(props) => css`
        margin-top: 48px;
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
        scroll-margin-top: 150px;
        &.gate-active {
            scroll-margin-top: 270px;
        }
        ${!props.theme.screen.desktop &&
        css`
            scroll-margin-top: 80px;
        `}
        ${props.theme.screen.mobile &&
        css`
            font-size: 18px;
            line-height: 22px;
            margin-top: 24px;
        `}
    `}
`;

type ProductArray = {
    productsArray: Product[];
};
export const Products = (props: ProductArray) => {
    const { screenViewPort, gateIsActive } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();

    return (
        <>
            {props.productsArray.length > 0 && (
                <Container>
                    <Header id="products" className={classNames({ 'gate-active': gateIsActive })}>
                        {t('products')}
                    </Header>
                    <ProductsContainer>
                        {props.productsArray.map((product, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <ProductWrap>
                                        {product.productImages.length > 0 && (
                                            <Img src={product.productImages[0].logoPath} />
                                        )}
                                        <Wrap>
                                            <Text className="name">{product.product}</Text>
                                            <Text>{product.description}</Text>
                                        </Wrap>
                                    </ProductWrap>
                                    {((screenViewPort !== 'mobile' &&
                                        index % 2 !== 0 &&
                                        index + 1 !== props.productsArray.length) ||
                                        (screenViewPort === 'mobile' && index + 1 !== props.productsArray.length)) && (
                                        <Divider />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </ProductsContainer>
                </Container>
            )}
        </>
    );
};
