import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import localStorage from '../../commons/localStorage';
import { SELECTED_LANGUAGE_KEY, SiteLangID } from '../../constants/languages';
import { AUTH_TOKEN_KEY } from '../../commons/constants';

const http = axios.create();
const bearer = (token: string | null) => `${token}`;

const userLanguage = localStorage.get(SELECTED_LANGUAGE_KEY);
const siteLangID = userLanguage ? Object.values(userLanguage)[0] : SiteLangID.LT;
const baseURL = process.env.REACT_APP_BASE_API_URL || 'https://imonesapi.tandemumstage.site';

http.defaults.baseURL = baseURL;
http.defaults.baseURL += `/api/${siteLangID}`;

const authEndpoints = [`${baseURL}/api/users`];

http.interceptors.response.use((response) => {
    if (response.data) {
        response.data = camelcaseKeys(response.data, { deep: true });
    }
    return response;
});

http.interceptors.request.use((config) => {
    if (authEndpoints.some((endpoint) => config.url?.startsWith(endpoint)) || config.withCredentials) {
        const token: string | undefined = localStorage.get(AUTH_TOKEN_KEY);
        if (token) {
            config.headers['token'] = bearer(token);
        }
    }
    return config;
});

export const parseAxiosError = (err: any): any => {
    if ('isAxiosError' in err) {
        const body = err as AxiosError<any>;
        if (body.response?.status === 429) {
            // Rate limiting by cloudflare
            return {
                statusCode: err.status,
                message: 'Too many requests, please wait and try again later',
                errorCode: 'internalServerError',
            };
        }
        return {
            statusCode: err.status,
            message: err.message,
            errorCode: 'internalServerError',
        };
    }
};

export default http;
