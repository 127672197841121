import { useEffect, useState } from 'react';
import { Backdrop, Box, CircularProgress, Fade, Modal } from '@mui/material';

import { getCompanyFeedback } from '../../../../../services/company.service';
import { FeedbackItem } from '../../../../../types/api';

import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Stars } from '../../../../Stars/Stars';

const ModalBody = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 8px;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            width: calc(100% - 48px) !important;
        `}
    `}
`;

const Heading = styled.div`
    font-size: 24px;
    font-weight: 700;
`;

const modalBoxStyles = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 458,
    width: '100%',
    border: '1px solid #e1e2e1',
    borderRadius: '10px',
    background: '#fff',
    padding: '24px',
    overflow: 'auto',
    maxHeight: '50vh',
};

const Wrapper = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        padding: 16px;
        border-radius: 8px;
        background-color: ${props.theme.colors.lightGrey};
        margin-bottom: 8px;
        border: 1px solid ${props.theme.colors.outlineGrey};
        gap: 8px;
    `}
`;

const FeedbackComment = styled.div`
    font-size: 16px;
`;

const NoReviews = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 18px;
        color: ${props.theme.colors.secondaryBlack};
        text-align: center;
    `}
`;

interface Props {
    companyId: number;
    isModalOpen: boolean;
    onClose: (value: boolean) => void;
}

export const TemporaryReviewModal = (props: Props) => {
    const { t } = useTranslation();
    const [feedback, setFeedback] = useState<FeedbackItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        getCompanyFeedback(props.companyId)
            .then((res) => {
                setFeedback(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const calculatuAverageRating = (item: FeedbackItem) => {
        const ratingValues = Object.values({
            ratingExpectation: item.ratingExpectation,
            ratingQuality: item.ratingQuality,
            ratingQpRatio: item.ratingQpRatio,
            ratingSpeed: item.ratingSpeed,
        });
        const sum = ratingValues.reduce((a, b) => a + b, 0);
        return sum / ratingValues.length;
    };

    return (
        <Modal
            open={props.isModalOpen}
            onClose={() => props.onClose(false)}
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={props.isModalOpen}>
                <ModalBody sx={modalBoxStyles}>
                    {loading ? (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: 'white',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    ) : (
                        <>
                            <Heading>{t('reviews')}</Heading>
                            {feedback.length > 0 ? (
                                feedback.map((item) => (
                                    <Wrapper>
                                        <Stars rating={calculatuAverageRating(item)} />
                                        <FeedbackComment>{item.feedback}</FeedbackComment>
                                    </Wrapper>
                                ))
                            ) : (
                                <NoReviews>{t('no_reviews')}</NoReviews>
                            )}
                        </>
                    )}
                </ModalBody>
            </Fade>
        </Modal>
    );
};
