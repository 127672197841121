import { connect } from 'react-redux';
import { IState } from '../../../store';
import { TopicHead } from '../../../types/api';

const PureTopicBreadcrumb = (head: TopicHead) => {
    return <span>{head?.name}</span>;
};

const mapStateToProps = (state: IState, props: any) => {
    const { categoryId, topicId, subtopicId } = props.match.params;
    const category = state.company.categoriesList.find((category) => category.id === parseInt(categoryId));
    const topic = category?.topics.find((topic) => topic.id === parseInt(topicId));
    const head = topic?.heads.find((head) => head.id === parseInt(subtopicId));

    return (
        head || {
            name: '...',
        }
    );
};

export default connect(mapStateToProps)(PureTopicBreadcrumb);
